import { Pivot, PivotItem } from "@fluentui/react";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { UpdateSelectedtabStatus } from "../../Redux/features/autoCompleteSuggestionHeader";
import { PivotStyles, selectedTabValue } from "../SearchAutoComplete/Styles/SearchAutoCompleteStyles";

const SuggestionsHeaderText: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAlias = useAppSelector((state) => state.filters.searchFilters.isAlias);
  const selectedTab = useAppSelector((state) => state.autoCompleteTabHeader.selectedTab);
  return (
    <div className="suggestionHeaderTabs">
      <div
        onClick={() => {
          if (isAlias === true) {
            dispatch(UpdateSelectedtabStatus({ selectedTab: "StockSymbol" }));
          } else {
            dispatch(UpdateSelectedtabStatus({ selectedTab: "Name" }));
          }
        }}
      >
        <Pivot
          className="suggestionHeader"
          styles={{
            link: { ...PivotStyles.link },
            linkIsSelected: { ...PivotStyles.linkIsSelected },
            text: {
              color: selectedTab === selectedTabValue.Name || selectedTab === selectedTabValue.StockSymbol ? "teal" : "black",
              fontSize:selectedTabValue.font,
            },
          }}
        >
          <PivotItem
            className="suggestionHeader"
            headerText={isAlias ? "Stock symbol" : "Name"}
            itemKey="Name"
          ></PivotItem>
        </Pivot>
      </div>
      <div
        onClick={() => {
          if (isAlias === true) {
            dispatch(UpdateSelectedtabStatus({ selectedTab: "ISIN" }));
          } else {
            dispatch(UpdateSelectedtabStatus({ selectedTab: "Tag" }));
          }
        }}
      >
        <Pivot
          aria-label="Basic Pivot Example"
          className="suggestionHeader"
          styles={{
            link: { ...PivotStyles.link },
            linkIsSelected: { ...PivotStyles.linkIsSelected },
            text: {
              color: selectedTab === selectedTabValue.Tag || selectedTab === selectedTabValue.Isin ? "teal" : "black",
              fontSize: selectedTabValue.font,
            },
          }}
        >
          <PivotItem headerText={isAlias ? "ISIN" : "Tags"}></PivotItem>
        </Pivot>
      </div>
    </div>
  );
};
const AutoCompleteSuggestionHeader = () => {};
export { AutoCompleteSuggestionHeader, SuggestionsHeaderText };
