import { post } from "../apiClient";

export async function uploadLogoRequest(values: {
  Name: any;
  Domain: any;
  Comments: any;
  imageFile: File;
  ImageUrl: string;
  IsImageFileGiven: any;
}) {
  try {
    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Domain", values.Domain);
    formData.append("Comments", values.Comments);
    formData.append("imageFile", values.imageFile);
    formData.append("ImageUrl", values.ImageUrl);
    formData.append("IsImageFileGiven", values.IsImageFileGiven);
    const response = await post("/brands", "wl-logostore-tester", formData, {
      "Content-Type": "multipart/form-data",
    });

    const uploadLogoResponse = {
      data: response.data,
      success: response.success,
    };

    return uploadLogoResponse;
  } catch (error) {
    throw error;
  }
}
