/* global Office, console */

function constructAbsoluteUrl(endpoint: string) {
  const url = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + endpoint;

  return url;
}

let loginDialog: Office.Dialog;
const dialogLoginUrl: string = constructAbsoluteUrl("/begin-login.html");

export const signInO365 = async (retryAuthCheck: () => void, setError: any) => {
  await Office.context.ui.displayDialogAsync(
    dialogLoginUrl,
    { height: 40, width: 30 },
    // { height: 40, width: 30, promptBeforeOpen: false }, // Don't do this, it stops messages being passed to the window
    (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        if (result.error.code === 12007) {
          setError(`Please wait, your login request is in progress.`);
        } else {
          setError(result.error.message);
        }
      } else {
        loginDialog = result.value;
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
        //loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
      }
    }
  );

  const processLoginMessage = async (
    args:
      | {
          message: string;
          origin: string | undefined;
        }
      | {
          error: number;
        }
  ) => {
    if ("error" in args) {
      console.error("Error in login dialog: " + args.error);
      loginDialog.close();
      return;
    }

    const messageFromDialog = JSON.parse(args.message);

    if (messageFromDialog.status == "login-success") {
      // We now have a valid access token.
      loginDialog.close();
      setError(undefined);
      await retryAuthCheck();
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      setError(JSON.stringify(messageFromDialog.message));

      loginDialog.close();
    }
  };
};
