import { IDropdownOption } from "@fluentui/react";

export const addLogoInputTypeOptions: IDropdownOption[] = [
    { key: "URL", text: "URL" },
    { key: "File", text: "File" },
  ];
  export const imageSizeLimit = 1024 * 1024;
  export const acceptedFileTypes = ["image/png", "image/jpeg", "image/svg+xml", "image/jpg"];
  // export const domainRegex =/^(?:https?:\/\/)?(?:www\.)?[^\s\/]+\.(?:in|com|co)(?:\/.{0,10}|$)/;
  export const domainRegex =/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/
