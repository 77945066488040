import React, { useEffect } from "react";
import { PhotoGridProps } from "../ImageSearch.Interfaces";
import { PhotoCell } from "../PhotoCell/PhotoCell";
import "./Styles/PhotoGrid.css";




export function PhotoGrid({ photos, selectedPhotos, handleSelectPhoto, onPhotosPlaced,currentImageOnTheGrid,setCurrentImageOnTheGrid}: PhotoGridProps) {
  useEffect(() => {
    // Check if there's more spaces than current photo batch for a nicer UI experience -
    // we can trigger the next page of photos either on more gaps or when the user scrolls to the bottom
    const grid = document.querySelector(`.pg-grid`);
    const cell = document.querySelector(`.pg-grid > div`);
    if (grid && cell) {
      const gridHeight = grid.clientHeight;
      const moreSpaces = grid.parentElement!.clientHeight > gridHeight - cell.clientHeight;
      onPhotosPlaced(moreSpaces);
    }
  }, [photos]);

  // const newArray:any=photos;
  
  const items = photos.map((data:any) => {
   
    return (

        <PhotoCell
          key={data.id}
          photo={data}
          onClick={(photo) => handleSelectPhoto(photo)}
          selectedPhotos={selectedPhotos}
          currentImageOnTheGrid={currentImageOnTheGrid}
          setCurrentImageOnTheGrid={setCurrentImageOnTheGrid}
        />
     
    );
  });

  return (
    <>
      <div className="pg-grid">{items}</div>
    </>
  );
}
