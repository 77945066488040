import React from "react";
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import {
  AddLogoButtonStyles,
  dialogContentStyles,
  footerStyles,
  missingOrgsModalStyles,
  requestLogoButtonStyles,
} from "./Styles/MissingOrgsPopUpStyles";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import { UpdateMissingOrgsList } from "../../../Redux/features/missingOrgs";
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};
export function MissingOrgsPopup({
  showMissingOrgsPopup,
  setMissingOrgsPopup,
  missingOrgs,
  setRequestMissingLogos,
  handleMissingLogo,
}: {
  setRequestMissingLogos: () => void;
  showMissingOrgsPopup: boolean;
  setMissingOrgsPopup: (value: boolean) => void;
  missingOrgs: string[];
  handleMissingLogo: () => void;
}) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const modalProps: any = React.useMemo(
    () => ({
      isBlocking: true,
      styles: missingOrgsModalStyles,
      dragOptions: showMissingOrgsPopup ? dragOptions : undefined,
    }),
    [showMissingOrgsPopup]
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: "No Images Found",
    subText: "LogoCloud cannot find logos for the following organizations within the selected search criteria.",
    styles: dialogContentStyles,
  };

  const requestMissingLogo = () => {
    handleMissingLogo();
    setRequestMissingLogos();
    setMissingOrgsPopup(false);
  };
  const onClose = () => {
    setMissingOrgsPopup(false);
    dispatch(UpdateMissingOrgsList({ missingOrgs: [] }));
  };
  return (
    <div>
      <Dialog
        hidden={!showMissingOrgsPopup}
        onDismiss={() => onClose()}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <div style={{ overflowY: "auto", maxHeight: "250px" }}>
          <ul>
            {missingOrgs.map((org) => (
              <li key={org}>{org}</li>
            ))}
          </ul>
        </div>

        <DialogFooter styles={footerStyles}>
          <PrimaryButton styles={requestLogoButtonStyles} onClick={() => requestMissingLogo()} text="Request Logos" />
          <PrimaryButton styles={AddLogoButtonStyles} onClick={() => history.push("/AddLogo")} text="Add Logos" />
        </DialogFooter>
      </Dialog>
    </div>
  );
}
