import { API } from "aws-amplify";
import wlConfig from "../../../wlConfig";
import { post, unauthenticatedPost } from "../../Services/API/apiClient";
import { getTenantId } from "../../../url-helpers";

export async function supportFormRequest(values: {
  category: string;
  subCategory: string;
  additionalInfo: string;
  requesterEmailAddress: string;
}) {
  try {
    const response = await unauthenticatedPost("/support-request", `wl-logostore-tester`, {
      category: values.category,
      subCategory: values.subCategory,
      additionalInfo: values.additionalInfo,
      requesterEmailAddress: values.requesterEmailAddress,
      systemName: await getTenantId(),
      version:wlConfig.version,
    });


    const jiraTicketResponse = {
      data: response.data,
      success: response.success,
      version:response.version,
    };

    return jiraTicketResponse;
  } catch (error) {
    //  return {
    //   sucess:false,
    //   message:" ",
    //   statusCode:error.
    //  }
    throw error;
  }
}
