import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { selectedOrgsRedux } from "../redux.interfaces";

const initialState: selectedOrgsRedux = {
  selectedOrgs: [],
};

export const Orgs = createSlice({
  name: "Orgs",
  initialState,
  reducers: {
    updateSelectedOrgs: (state, action: PayloadAction<{ NewOrgNames: string[] }>) => {
      action.payload.NewOrgNames.forEach((element: string) => {
        state.selectedOrgs.push(element);
      });
    },
    resetSelectedOrgs: (_state) => (_state = initialState),
  },
});

export default Orgs.reducer;
export const { updateSelectedOrgs, resetSelectedOrgs } = Orgs.actions;
