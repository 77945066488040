/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/store";
import { Auth } from "aws-amplify";
import { getSession } from "../Util/LogInHelpers";
import SearchPage from "../Pages/SearchPage/searchPage";
import { UpdateAuthStatus } from "../Redux/features/authStatus";
import { isUserLoggedIn } from "../Services/Auth/auth";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const RenderComponent = () => {
    useEffect(() => {
      isUserLoggedIn().then((isLogin) => {
        if (isLogin) {
          dispatch(UpdateAuthStatus({ isAuthenticated: true }));
          history.push(rest.path);
        } else {
          dispatch(UpdateAuthStatus({ isAuthenticated: false }));
          history.push("/start-page");
        }
      });
    }, []);

    return <Component />;
  };

  return <Route {...rest} render={() => <RenderComponent />} />;
};

export default ProtectedRoute;
