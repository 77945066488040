import { getTheme, mergeStyleSets } from "@fluentui/react";
import { PhotoClassNames } from "../../ImageSearch.Interfaces";

const theme = getTheme();


export const getClassNames = (): PhotoClassNames => {
  return mergeStyleSets({
    latestRibbon: {
      inset: "8px auto auto calc(-1*6px)",
      padding: "0 calc(6px + 2px) 0px 6px",
      boxShadow: "0px 2px 2px 0px rgba(24,84,87,0.5)",
      "webkit-box-shadow": "0px 2px 2px 0px rgba(24,84,87,0.5)",
      "moz-box-shadow": "0px 2px 2px 0px rgba(24,84,87,0.5)",
      zIndex: "2",
      position: "fixed",
      background: "teal",
      color: theme.palette.white,
      fontWeight: 600,
      fontSize: "12px",
    },
    photoGridPhotoClass:{
      cursor: "pointer",
      position: "relative",
    },
    photoGridTransparentBackgroundClass:{
      cursor: "pointer",
      background: "repeating-conic-gradient(#ddd 0% 25%, transparent 0% 50%) 50% / 12% 12%",
      backgroundColor: "#eee",
      position: "relative",
    }
  });
};
