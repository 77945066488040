/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import { Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { getSession } from "../../Util/LogInHelpers";
import { UpdateAuthStatus } from "../../Redux/features/authStatus";
import { useAppDispatch, useAppSelector } from "../../Redux/store/store";
import { Redirect, useHistory } from "react-router-dom";
import { isUserLoggedIn } from "../../Services/Auth/auth";
import { useSelector } from "react-redux";
function Busy() {
  return (
    <Spinner
      label="Please wait..."
      ariaLive="assertive"
      labelPosition="bottom"
      size={SpinnerSize.large}
      className="rqo-spinner"
    />
  );
}
export function AuthCheck() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    isUserLoggedIn().then((isLogin) => {
      if (isLogin) {
        dispatch(UpdateAuthStatus({ isAuthenticated: true }));
        history.push("/search");
      } else {
        dispatch(UpdateAuthStatus({ isAuthenticated: false }));
        history.push("/start-page");
      }
    });
  }, []);

  return <Busy />;
}
