import React, { useState, useEffect } from "react";
import { PrimaryButton, DefaultButton, TextField, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "./Styles/SignUpConfirmation.css";
import { error } from "./SignUpConfirmation.Interfaces";

const SignUpConfirmation = ({ email }: { email: string }) => {
  const [errorMessage, setErrorMessage] = useState<error>({ message: "", title: "" });
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [apiResponse, setApiResponse] = useState<boolean>(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: email,
      confirmationCode: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Email is required."),
      confirmationCode: Yup.string()
        .required("Code is required.")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(4, "Must be 4 digits")
        .max(6, "Must be 6 digits"),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      confirmUser(values);
    },
  });

  const confirmUser = async (values: { email: string; confirmationCode: string }) => {
    try {
      const user = await Auth.confirmSignUp(values.email, values.confirmationCode, { forceAliasCreation: true });
      if (user === "SUCCESS") {
        setSuccessMessage("User successfully registered");
      }
    } catch (error) {
      let errorMessage = handleAuthError(error);
      setErrorMessage(errorMessage);
    }
    setApiResponse(true);
  };

  const handleAuthError = (error: any) => {
    switch (error.code) {
      case "UsernameExistsException":
        return {
          message: "An error is encountered while registering your account. Please contact service desk.",
          title: "User Already Exist",
        };
      case "InvalidPasswordException":
        return { message: error.message, title: "Invalid Password" };
      default:
        return { message: error.message, title: error.code };
    }
  };

  return (
    <div className="page">
      <h3 className="page-slogan">Sign in to start adding logos.</h3>
      <h3 className="page-heading">Confirmation code</h3>
      <form onSubmit={formik.handleSubmit}>
        {/* Email */}
        <label className="form-field-label ">
          <span className="asterisk-red">*</span>Email
        </label>
        <TextField {...formik.getFieldProps("email")} />
        <div className="validation-error">{formik.touched.email && formik.errors.email}</div>

        {/* Code */}
        <label className="form-field-label form-field-spacing">
          <span className="asterisk-red">*</span>Code
        </label>
        <TextField {...formik.getFieldProps("confirmationCode")} />
        <div className="validation-error">{formik.touched.confirmationCode && formik.errors.confirmationCode}</div>

        <div className="btns-container">
          <PrimaryButton type="submit">Submit</PrimaryButton>
          <DefaultButton text="Cancel" onClick={() => history.push("/")} />
        </div>
      </form>

      {errorMessage.message != "" && (
        <div className="validation-error sign-upc-error-message">Error: {errorMessage.message}</div>
      )}

      {apiResponse && successMessage != "" && (
        <Dialog
          hidden={!apiResponse}
          onDismiss={() => history.push("/")}
          dialogContentProps={{
            type: DialogType.normal,
            title: "User successfully registered.",
          }}
        >
          <DialogFooter className="sign-upc-footer">
            <PrimaryButton onClick={() => history.push("/")} text="Ok" />
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
};

export default SignUpConfirmation;
