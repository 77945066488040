import { Auth } from "aws-amplify";
import { post } from "../apiClient";
import { getTenantId } from "../../../../url-helpers";

export async function createTicketForMissingLogos(
  missingOrgs: string[],
  searchFilters: any,
  setIsRequestMissingLogosSuccessful: (data: any) => void
) {
  const user = await Auth.currentAuthenticatedUser();
  const registeredEmailID = user.signInUserSession.idToken.payload.email;

  const response = await post("/support-request", "wl-logostore-tester", {
    category: "Content",
    subCategory: "New logo request",
    additionalInfo: `Testing - please IGNORE this ticket -  Hi The following organization images are not available. Please add them to LogoCloud. Thanks, ${registeredEmailID}. List of organization missing images: ${missingOrgs} ; Search filter criteria: colour: ${searchFilters.colour}, isLatest : ${searchFilters.isLatest}, isBest: ${searchFilters.isBest}, format: ${searchFilters.format}, opacity : ${searchFilters.opacity}, type: ${searchFilters.type}, isAsc: ${searchFilters.isAsc}, sort: ${searchFilters.sort}, isAlias: ${searchFilters.isAlias}.`,
    requesterEmailAddress: registeredEmailID,
    systemName: await getTenantId(),
    missingLogos:missingOrgs,
  });

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    throw err.message ?? "An error occurred";
  }

  const data = await JSON.parse(JSON.stringify(response));
  setIsRequestMissingLogosSuccessful(data.data.reference);
}
