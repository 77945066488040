import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { authStatusRedux } from "../redux.interfaces";

const initialState: authStatusRedux = {
  currentSessionValid:false,
  isAuthenticated: false,
  showLogOutDialog: false,
};

export const AuthStatusSlice = createSlice({
  name: "authStatus",
  initialState,
  reducers: {
    UpdateAuthStatus: (state, action: PayloadAction<{ isAuthenticated: boolean }>) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

export default AuthStatusSlice.reducer;
export const { UpdateAuthStatus } = AuthStatusSlice.actions;
