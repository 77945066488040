import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { logoPadding } from "../redux.interfaces";

const initialState: logoPadding = {
  logoPadding: 0,
};

export const LogoPaddingSlice = createSlice({
  name: "logoPadding",
  initialState,
  reducers: {
    UpdateLogoPadding: (state, action: PayloadAction<{ logoPadding: number }>) => {
      state.logoPadding = action.payload.logoPadding;
    },
  },
});

export default LogoPaddingSlice.reducer;
export const { UpdateLogoPadding } = LogoPaddingSlice.actions;
