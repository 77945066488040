import { IChoiceGroupOption, IDropdownOption } from "@fluentui/react";

export const formatOptions: IChoiceGroupOption[] = [
  { key: "any", text: "Any" },
  { key: "png", text: "PNG" },
  { key: "jpeg", text: "JPEG" },
  { key: "svg", text: "SVG" },
];

export const typeOptions: IChoiceGroupOption[] = [
  { key: "all", text: "All" },
  { key: "logo", text: "Logo" },
  { key: "icon", text: "Icon" },
];

export const opacityOptions: IChoiceGroupOption[] = [
  { key: "any", text: "Any" },
  { key: "transparent", text: "Transparent" },
  { key: "opaque", text: "Opaque" },
];

export const colourOptions: IChoiceGroupOption[] = [
  { key: "any", text: "Any" },
  { key: "standard", text: "Standard" },
  { key: "white", text: "White" },
];
export const themeOptions: IChoiceGroupOption[] = [
  { key: "any", text: "Any" },
  { key: "dark", text: "Dark" },
  { key: "light", text: "Light" },
];
export const sortOptions: IDropdownOption[] = [
  { key: "searchOrder", text: "Search Order" },
  { key: "alphabetically", text: "Alphabetically" },
];

export const ColourFilterOptions: IDropdownOption[] = [
  { key: "Any", text: "Any" },
  { key: "Standard", text: "Standard" },
  { key: "White", text: "White" },
];
