import { mergeStyleSets } from "@fluentui/merge-styles";
import { IFilterPanelClassNames } from "../FilterPanel.Interfaces";
import { IChoiceGroupStyles, IDropdownStyles } from "@fluentui/react";

export const getClassNames = (): IFilterPanelClassNames => {
  return mergeStyleSets({
    topFilters: {
      display: "flex",
      alignItems: "center",
      gap: "30px",
      // gap: "14px",
      marginBottom: "8px",
      label: {
        fontSize: "12px",
      },
    },
    filterAscending: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginTop: "9px",
      marginRight: "10px",
      label: {
        fontSize: "12px",
        marginBottom: "2px",
      },
    },
  });
};

export const RadioButtonsStyles: IChoiceGroupStyles = {
  root: {
    '& div[role="radiogroup"]': {
      display: "flex",
      alignItems: "center",
    },
  },
  label: { fontSize: "12px", width: "70px", marginBottom: "5px" },
  flexContainer: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    order: "2",
    marginBottom: "8px",
    marginRight: "10px",
    label: {
      fontSize: "12px",
    },
  },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: { display: "flex", alignItems: "center" },
  label: { fontSize: "12px", marginRight: "20px" },
  dropdown: { width: "125px" },
};
