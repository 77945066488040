import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { suggestedOrgsRedux } from "../redux.interfaces";
import { useRef } from "react";

const initialState: suggestedOrgsRedux = {
  newOrgs: [],
  orgSelected: false,
};

export const SuggestedOrgs = createSlice({
  name: "suggestedOrgs",
  initialState,
  reducers: {
    addSelectedSuggestions: (
      state,
      action: PayloadAction<{
        selectedSuggestions: {
          searchName: string;
          searchFieldName: any;
          suggestions: any[];
        };
      }>
    ) => {
      // if empty, allow push without duplication checking
      if (state.newOrgs.length === 0) {
        state.newOrgs.push(action.payload.selectedSuggestions);
      }

      // check if incoming entry name matches existing entry
      // if yes, delete existing(old) entry and replace with incoming(new) entry to update
      if (state.newOrgs.length > 0) {
        let newArr = state.newOrgs.filter((el) => {
          return el.searchName !== action.payload.selectedSuggestions.searchName;
        });

        newArr.push(action.payload.selectedSuggestions);
        state.newOrgs = newArr;
      }
    },
    resetSelectedSuggestions: (state) => {
      state.newOrgs = [];
    },
  },
});

export default SuggestedOrgs.reducer;
export const { addSelectedSuggestions, resetSelectedSuggestions } = SuggestedOrgs.actions;
