import { get } from "../apiClient";
import { nanoid } from "nanoid";
import { OrgResults } from "./fetchOrgNames.Interfaces";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";

export const fetchOrgNames = async (query: string,isAlias:boolean): Promise<OrgResults> => {
  if (query.trim() === "") {
    return { orgs: [], orgCount: 0 };
  }

  const encodedQuery = encodeURIComponent(query); // Encoded the query string

  const response: any = await get(`/organisations?startswith=${encodedQuery}&isAlias=${isAlias}`, "wl-logostore-tester");



  if (response == null) {
    return { orgs: [], orgCount: 0 };
  }

  if (!response.success) {
    throw new Error(response.message);
  }

  const json = await JSON.parse(JSON.stringify(response));

  if (json.data == null) {
    return { orgs: [], orgCount: 0 };
  }

  const orgs = json.data.map((data: any) => ({
    id: nanoid(),
    name: data,
  }));

  const orgCount = json.data;

  return {
    orgs:json.data,
    orgCount: orgCount.count,
  };
};
