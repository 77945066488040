import { IDropdownOption } from "@fluentui/react/lib/Dropdown";

export const categoryOptions: IDropdownOption[] = [
  { key: "Content", text: "Content" },
  { key: "Technical", text: "Technical" },
];
export const initialSubCategory: IDropdownOption[] = [{ key: " ", text: " " }];

export const TechnicalSubCategory: IDropdownOption[] = [
  { key: "Log-in issue", text: "Log-in issue" },
  { key: "Search issue", text: "Search issue" },
  { key: "Filter issue", text: "Filter issue" },
  { key: "Inserting issue", text: "Inserting issue" },
  { key: "Other", text: "Other" },
];

export const contentSubCategory: IDropdownOption[] = [
  { key: "Quality", text: "Quality" },
  { key: "Branding/variation issue", text: "Branding/variation issue" },
  { key: "Inaccurate logo", text: "Inaccurate logo" },
  { key: "New logo request", text: "New logo request" },
  { key: "Other", text: "Other" },
];
