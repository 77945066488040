import { post } from "../apiClient";

export async function fuzzySearch(names: any,isAlias:boolean) {
 
  if (names.length === 0) {
    return null;
  }

  const response = await post("/organisations/fuzzy-search", "wl-logostore-tester", { names,isAlias});

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    throw err.message ?? "An error occurred";
  }

  const json = await JSON.parse(JSON.stringify(response));

  if (json.data == null) {
    return null;
  }

  return json;
}
