import {
  IDropdownStyles,
  ISpinButtonStyles,
  mergeStyleSets,
  mergeStyles,
  ICheckboxStyles,
  IDialogContentStyleProps,
  IDialogStyles,
} from "@fluentui/react";

export const SpinnerStyles: Partial<ISpinButtonStyles> = {
  root: { display: "flex", gap: "50px", marginTop: "2px" },
  labelWrapper: { width: "81px", marginLeft: "10px", height: "25px" },
  spinButtonWrapper: { width: "3rem", height: "25px" },
};

export const ColumnSpinnerStyles: Partial<ISpinButtonStyles> = {
  root: { display: "flex", gap: "50px", marginBottom: "2px", marginTop: "2px" },
  labelWrapper: { width: "81px", marginLeft: "10px", height: "25px" },
  spinButtonWrapper: { width: "3rem", height: "25px" },
};

export const RowSpinnerStyles: Partial<ISpinButtonStyles> = {
  root: { display: "flex", gap: "50px", marginBottom: "3px", marginTop: "2px" },
  labelWrapper: { width: "81px", marginLeft: "10px", height: "25px" },
  spinButtonWrapper: { width: "3rem", height: "25px" },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: { display: "flex", gap: "10px", alignItems: "center", marginBottom: "-1px" },
  label: { width: "130px", marginLeft: "10px" },
  dropdown: { width: "86px", fontSize: "13px", height: "25px" },
  dropdownItem: { fontSize: "13px", height: "25px" },
  dropdownItemSelected: { fontSize: "13px" },
  caretDownWrapper: { lineHeight: "27px" },
  title: { height: "25px", fontSize: "13px", lineHeight: "23px" },
};

export const distributeCheckBoxStyles: Partial<ICheckboxStyles> = {
  root: { display: "flex", width: "100%", justifyContent: "space-between" },
  label: {
    width: "150px",
    // marginTop: "5px",
    fontWeight: "600",
    fontSize: "10px",
    marginLeft: "10px",
    whiteSpace: "nowrap",
  },
  checkbox: {
    marginTop: "1px",
    fontSize: "16px",
    marginLeft: "60px",
    width: "18px",
    height: "18px",
  },
};
export const retainShapeCheckBoxStyles: Partial<ICheckboxStyles> = {
  root: { display: "flex", gap: "50px" },
  label: {
    marginTop: "5px",
    fontWeight: "600",
    fontSize: "10px",
    marginLeft: "10px",
  },
  checkbox: {
    marginTop: "1px",
    fontSize: "16px",
    marginLeft: "121px",
    width: "18px",
    height: "18px",
  },
};
export const compressImagesCheckBoxStyles: Partial<ICheckboxStyles> = {
  root: { display: "flex", gap: "50px" },
  label: {
    marginTop: "5px",
    fontWeight: "600",
    fontSize: "10px",
    marginLeft: "10px",
  },
  checkbox: {
    marginTop: "1px",
    marginLeft: "91px",
    fontSize: "16px",
    width: "18px",
    height: "18px",
  },
};
