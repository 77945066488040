import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../store/store";
import { selectedTabRedux } from "../redux.interfaces";

const initialState: selectedTabRedux = {
  //  selectedTab:isAlias ? "StockSymbol" :"Name"
  selectedTab: "Name",
};

export const selectedTabSlice = createSlice({
  name: "selectedTab",
  initialState,
  reducers: {
    UpdateSelectedtabStatus: (state, action: PayloadAction<{ selectedTab: string }>) => {
      state.selectedTab = action.payload.selectedTab;
    },
  },
});

export default selectedTabSlice.reducer;
export const { UpdateSelectedtabStatus } = selectedTabSlice.actions;
