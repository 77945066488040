import { SetStateAction } from "react";
import { getInsertableSelectedShape } from "../../Components/ImageInsertion/Apps/Powerpoint";

//check is shape selected
export async function findShape(): Promise<any> {
  return PowerPoint.run(async (context) => {
    const shape = await getInsertableSelectedShape(context);
    return shape;
  });
}

export function truncateOrgName(
  orgName: string,
  maxWidth: number,
  textCtx: CanvasRenderingContext2D,
  maxHeight: number,
  lineHeight: number
) {
  const ellipsis = "...";
  const lines = [];
  let currentLine = "";
  let currentLineWidth = 0;
  let totalTextHeight = 0;
 
  for (let i = 0; i < orgName.length; i++) {
    const char = orgName[i];
    const charWidth = textCtx.measureText(char).width;

   

    if (totalTextHeight + lineHeight <= (maxHeight / 4) * 3) {
      // Check if adding the line height would exceed maxHeight
      if (currentLineWidth + charWidth <= maxWidth) {
        currentLine += char;
        currentLineWidth += charWidth;
      } else {
        // Start a new line if maxWidth is exceeded
        lines.push(currentLine);
        currentLine = char;
        currentLineWidth = charWidth;
        totalTextHeight += lineHeight;
      }
    } else {
      // Maximum height reached, break the loop
      break;
    }
  }

  if (currentLine.length > 0) {
    lines.push(currentLine);
  }

  // Check if the orgName and lines are not an exact match
  if (lines.join("") !== orgName) {
    const lastLineIndex = lines.length - 1;
    const lastLine = lines[lastLineIndex];
    // Add ellipsis to the last line
    lines[lastLineIndex] = lastLine.slice(0, -1) + ellipsis;
  }

  return { lines, totalTextHeight };
}
