import React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, IModalProps, PrimaryButton } from "@fluentui/react";
import { Auth } from "aws-amplify";
import { UpdateAuthStatus } from "../../Redux/features/authStatus";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../Redux/store/store";

export function ConfirmSignOut({
  showLogOut,
  setShowLogOut,
}: {
  showLogOut: boolean;
  setShowLogOut: (arg0: boolean) => void;
}) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Confirm sign out",
    closeButtonAriaLabel: "Stay logged in",
    subText: "Are you sure you want to sign out?",
  };

  const modalProps: IModalProps = {
    styles: {
      main: {
        minHeight: "inherit",
      },
    },
  };

  const logout = async () => {
    // await Auth.signOut();
    dispatch(UpdateAuthStatus({ isAuthenticated: false }));
    localStorage.clear();
    history.push("/");
  };

  return (
    <Dialog
      hidden={!showLogOut}
      onDismiss={() => setShowLogOut(false)}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={async () => {
            logout();
            setShowLogOut(false);
          }}
          text="Sign Out"
        />
        <DefaultButton onClick={async () => setShowLogOut(false)} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
}
