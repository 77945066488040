import { Dialog, DialogFooter, DialogType, IModalProps, PrimaryButton } from "@fluentui/react";
import React from "react";
import wlConfig from "../../../wlConfig";

function VersionPopUp({
  isShowVersionActive,
  setIsShowVersionActive,
}: {
  isShowVersionActive: boolean;
  setIsShowVersionActive: () => void;
}) {
  const modalProps: IModalProps = {
    styles: {
      main: {
        minHeight: "inherit",
        width: "200px",
      },
    },
  };
  return (
    <Dialog
      hidden={!isShowVersionActive}
      modalProps={modalProps}
      dialogContentProps={{
        type: DialogType.normal,

        title: (
          <div>
            <img className="lcVersionTitle" src="assets/LogoCloud_HORZ_RGB_169x32.png" alt="Logo" />
          </div>
        ),
      }}
    >
      <div className="versionContainer">
        <p>Version - {wlConfig.version}</p>

        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setIsShowVersionActive();
            }}
            text="Ok"
          />
        </DialogFooter>
      </div>
    </Dialog>
  );
}

export default VersionPopUp;
