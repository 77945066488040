export const dialogContentStyles = {
    title: {
      fontSize: "18px",
      textAlign: "center",
    },
    subText: {
        color:"black",
      fontSize: "13px",
      textAlign: "Left",
      padding: "0px",
      marginBottom:"8px",
    },
  };
  