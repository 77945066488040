import React, { useEffect, useState } from "react";
import { Toggle } from "@fluentui/react";
import { addSelectedSuggestions } from "../../../Redux/features/suggestedOrgs";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import { SingleOrgSuggestionProps } from "./SingleOrgSuggestion.Interfaces";
import "./Styles/SingleOrgSuggestionsStyles.css";

const IndividualOrgSuggestion = ({
  index,
  item,
  name,
  selectedSuggestions,
  setSelectedSuggestions,
  displayName,
  searchFieldName,
}: SingleOrgSuggestionProps) => {
  const dispatch = useAppDispatch();
  const newOrgs = useAppSelector((state) => state.suggestedOrgs.newOrgs);

  // Initialize orgSelected based on whether the suggestion is in the selectedSuggestions array
  const [orgSelected, setOrgSelected] = useState<boolean>(
    selectedSuggestions.includes(item.searchKeywordToUse ?? item.orgName)
  );

  const sendValues = () => {
    const selected = [item.searchKeywordToUse ?? item.orgName];
    const filteredSuggestionsArray = Array.from(new Set(selected));

    // Toggle the selection for the clicked suggestion
    setOrgSelected(!orgSelected);

    // Clear previous selected suggestions
    setSelectedSuggestions([]);

    const extractedSuggestion = filteredSuggestionsArray.map((suggestion: any) => {
      return suggestion;
    });

    // If the suggestion is selected, add it to the selected suggestions
    if (!orgSelected) {
      dispatch(
        addSelectedSuggestions({
          selectedSuggestions: {
            searchName: name,
            suggestions: extractedSuggestion,
            searchFieldName: searchFieldName,
          },
        })
      );
    } else {
      // If the suggestion is deselected, remove it from the selected suggestions
      dispatch(
        addSelectedSuggestions({
          selectedSuggestions: { searchName: name, suggestions: [], searchFieldName: "" },
        })
      );
    }
  };

  useEffect(() => {
    // Check if the suggestion is in the selectedSuggestions array
    setOrgSelected(selectedSuggestions.includes(item.searchKeywordToUse ?? item.orgName));

    newOrgs.forEach((el: any) => {
      if (el.searchName === name && el.suggestions.includes(item.searchKeywordToUse ?? item.orgName)) {
        setOrgSelected(true);
      }
    });
  }, [item, name, newOrgs, selectedSuggestions]);

  return (
    <div key={index} className="org-suggestion-container">
      <div>{displayName}</div>
      <Toggle
        className="org-suggestion-toggle"
        checked={orgSelected}
        onChange={() => {
          sendValues();
        }}
      />
    </div>
  );
};

export default IndividualOrgSuggestion;
