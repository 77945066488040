import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../store/store";
import { missingOrgsFormatRedux } from "../redux.interfaces";

const initialState: missingOrgsFormatRedux = {
  //  selectedTab:isAlias ? "StockSymbol" :"Name"
  missingOrgs:[],
};

export const missingOrgsSlice = createSlice({
  name: "missingOrgs",
  initialState,
  reducers: {
    UpdateMissingOrgsList: (state, action: PayloadAction<{ missingOrgs: any }>) => {
      state.missingOrgs = action.payload.missingOrgs;
    },
  },
});

export default missingOrgsSlice.reducer;
export const { UpdateMissingOrgsList } = missingOrgsSlice.actions;