/* global Word */
import { Base64Image } from "../ImageInsertion.Interfaces";

const DefaultImageWidthPts = 180; // 2.5 inches

export async function insertImagesIntoDocument(base64Images: Base64Image[]) {
  await Word.run(async (context) => {
    for (const base64Image of base64Images.reverse()) {
      const sel = context.document.getSelection();

      const img = sel.insertInlinePictureFromBase64(base64Image.data, Word.InsertLocation.end);
      sel.insertParagraph("", Word.InsertLocation.after);
      img.width = DefaultImageWidthPts;
    }
  });
}

export async function findMinimumImageWidth() {
  return DefaultImageWidthPts;
}
