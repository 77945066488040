import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Styles/HamburgerMenu.css";
import wlConfig from "../../../wlConfig";
import { Button, Dialog, DialogFooter, DialogType, PrimaryButton, IModalProps } from "@fluentui/react";
import VersionPopUp from "./VersionPopUp";

const HamburgerMenu = ({ setShowLogOut }: { setShowLogOut: (arg0: boolean) => void }) => {
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const [isAboutLinkActive, setIsAboutLinkActive] = useState(false);
  const [isShowVersionActive, setIsShowVersionActive] = useState(false);
  const history = useHistory();
  const clickRef = useRef<any>(null);
  const menuRef = useRef<any>(null);

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== clickRef.current) {
      setIsHamburgerActive(false);
    }
  });
  const modalProps: IModalProps = {
    styles: {
      main: {
        minHeight: "inherit",
      },
    },
  };

  return (
    <>
      <img
        ref={menuRef}
        className="hamburger"
        onClick={() => {
          setIsHamburgerActive((prev) => !prev);
        }}
        src="assets/MenuIcon.png"
        alt="MenuIcon"
      />

      {isHamburgerActive && (
        <div ref={clickRef} className="hamburger-content">
          <ul className="hamburger-list">
            <li className="hamburger-item">
              <a className="hamburger-link" onClick={() => history.push("/addLogo")}>
                <img className="hamburger-upload-logo-icon" src="assets/uploadLogo.jpg" />
                <span className="hamburger-link">Add Logo</span>
              </a>
            </li>
            {/* Contact Support */}
            <li className="hamburger-item">
              <a className="hamburger-link" onClick={() => history.push("/support")}>
                <img className="hamburger-icon" src="assets/ContactSupport.png" />
                <span className="hamburger-link">Contact Support</span>
              </a>
            </li>
            <li className="About-LC-Menu hamburger-item">
              <img className="about-icon" src="assets/About.png" />
              <span className=" hamburger-link" onClick={() => setIsAboutLinkActive(!isAboutLinkActive)}>
              <a className="hamburger-link" href="https://support.wl-logocloud.com/page/About" target="_blank">
                About LC<img src="assets/PopOut.png" className="pop-out-icon" /><img className="subMenuNavIcon"src="assets/subMenuNavIcon.png"></img>
                </a>
              </span>
              <ul className="subMenu">
                <li className="hamburger-item">
                  <a
                    className="hamburger-link"
                    href="https://support.wl-logocloud.com/space/LogoCloud/2024374293/Product+Guide"
                    target="_blank"
                  >
                    <img className="hamburger-icon" src="assets/UserGuide.png" />
                    <span className="hamburger-link">User Guide</span>
                    <img src="assets/PopOut.png" className="pop-out-icon" />
                  </a>
                </li>
                <li className="hamburger-item">
                  <a
                    className="hamburger-link"
                    href="https://support.wl-logocloud.com/space/LogoCloud/2024341524/Release+Notes"
                    target="_blank"
                  >
                    <img className="hamburger-icon" src="assets/ReleaseNotes.png" />
                    <span className="hamburger-link">Release Notes</span>
                    <img src="assets/PopOut.png" className="pop-out-icon" />
                  </a>
                </li>
        
                <li>
                  <a className="hamburger-link" href="https://www.williamslea.com/" target="_blank">
                    <img className="hamburger-icon" src="assets/WilliamsLea.png" />
                    <span className="hamburger-link">Williams Lea</span>
                    <img src="assets/PopOut.png" className="pop-out-icon" />
                  </a>
                </li>
                <li>
                  <a className="hamburger-link" href="https://www.williamslea.com/privacy-statement" target="_blank">
                    <img className="hamburger-icon" src="assets/WilliamsLea.png" />
                    <span className="hamburger-link">Privacy Policy</span>
                    <img src="assets/PopOut.png" className="pop-out-icon" />
                  </a>
                </li>
                <li>
              <a className="hamburger-link" onClick={() => setIsShowVersionActive(true)}>
                {" "}
                <img className="about-icon" src="assets/About.png" />
                <span>Version</span>
              </a>
            </li>
              </ul>
            </li>
            <li className="hamburger-item">
              <a className="hamburger-link" onClick={() => setShowLogOut(true)}>
                <img className="hamburger-icon" src="assets/LogOut.png" />
                <span className="hamburger-link">Log Out</span>
                {/* <img src="assets/PopOut.png" className="pop-out-icon" /> */}
              </a>
            </li>
          </ul>
        </div>
      )}
      {isShowVersionActive && (
        <VersionPopUp
          isShowVersionActive={isShowVersionActive}
          setIsShowVersionActive={() => setIsShowVersionActive(false)}
        />
      )}
    </>
  );
};

export default HamburgerMenu;
