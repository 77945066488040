import { IModalProps } from "@fluentui/react";

export const dialogContentStyles = {
  title: {
    fontSize: "18px",
    textAlign: "center",
  },
  subText: {
    fontSize: "14px",
    textAlign: "center",
    padding: "0px",
  },
};

export const footerStyles = {
  actions: {
    display: "flex",
    justifyContent: "center",
  },
};

export const retainShapeValidationErrorPopUpModelProps: IModalProps = {
  styles: {
    main: {
      minHeight: "inherit",
      maxHeight: "500px",
    },
  },
};
