import React, { useState } from "react";
import { PrimaryButton, DefaultButton, TextField } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordVerification from "../../Components/PasswordVerification/PasswordVerification";
import { error } from "./ForgotPasswordPage.Interface";
import "./Styles/ForgotPasswordPage.css";

const ResetPasswordPage = () => {
  const [email, setRegisteredEmail] = useState<string>("");
  const [passwordVerification, setPasswordVerification] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<error>({ message: "", title: "" });
  const history = useHistory();

  async function forgotPasswordFunc(values: { email: string }) {
    try {
      await Auth.forgotPassword(values.email);
      setPasswordVerification(!passwordVerification);
      setRegisteredEmail(values.email);
    } catch (error) {
      let errorMessage = handleError(error);
      setErrorMessage(await errorMessage);
    }
  }

  async function handleError(error: any) {
    switch (error.code) {
      case "LimitExceededException":
        return {
          message: "Attempt limit exceeded, please try after some time.",
          title: "LimitExceededException",
        };

      default:
        return { message: error.message, title: error.code };
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required."),
    }),
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      forgotPasswordFunc(values);
      setRegisteredEmail(values.email);
    },
  });

  return (
    <>
      {!passwordVerification && (
        <div className="page">
          <h3 className="page-slogan">Sign in to start adding logos.</h3>
          <h3 className="page-heading">Forgot password</h3>

          <form onSubmit={formik.handleSubmit}>
          
            <label className="form-field-label">
              <span className="asterisk-red">*</span>Email
            </label>
            <TextField {...formik.getFieldProps("email")} />
            {formik.touched.email && formik.errors.email ? (
              <div className="validation-error">{formik.errors.email}</div>
            ) : null}
          
           
            <div className="btns-container">
              <PrimaryButton type="submit" text="Submit" />
              <DefaultButton text="Cancel" onClick={() => history.push("/")} />
            </div>
          </form>

          {errorMessage.message != "" && (
            <div className="validation-error form-field-spacing">Error: {errorMessage.message}</div>
          )}
        </div>
      )}

      {passwordVerification && <PasswordVerification email={email} />}
    </>
  );
};

export default ResetPasswordPage;
