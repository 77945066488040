import IndividualOrgSuggestion from "../IndividualOrgSuggestions/SingleOrgSuggestion";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import { addSelectedSuggestions, resetSelectedSuggestions } from "../../../Redux/features/suggestedOrgs";
import "./Styles/OrgSuggestions.css";

import React, { useEffect, useState } from "react";
import { OrgSuggestionsProps } from "./OrgSuggestions.Interfaces";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-components";

const OrgSuggestions = ({
  name,
  suggestionsPage,
  setSuggestionsPage,
  index,
  totalPage,
  suggestionsList,
  setLoading,
  discardDYMSuggestion,
  setDidYouMeanNeeded,
  handleAppliedBulkSearch,
}: OrgSuggestionsProps) => {
  const dispatch = useAppDispatch();
  const [selectedSuggestions, setSelectedSuggestions] = useState<any[]>([]);
  const [nameStock, setNameStock] = useState<any[]>([]);
  const [tagsISIN, setTagsISIN] = useState<any[]>([]);

  const newOrgs = useAppSelector((state) => state.suggestedOrgs.newOrgs);

  const sortSuggestionsLists = () => {
    suggestionsList.forEach((suggestion: any) => {
      if (!isAlias) {
        if (suggestion.grpName.includes("Name")) {
          setNameStock(suggestion.items);
        }

        if (suggestion.grpName.includes("Tags")) {
          setTagsISIN(suggestion.items);
        }
      } else {
        if (suggestion.grpName.includes("StockSymbol")) {
          setNameStock(suggestion.items);
        }

        if (suggestion.grpName.includes("ISIN")) {
          setTagsISIN(suggestion.items);
        }
      }
    });
  };

  useEffect(() => {
    sortSuggestionsLists();

    newOrgs.forEach((el: any) => {
      if (el.searchName === name && el.suggestions.length !== 0) {
        setSelectedSuggestions([...el.suggestions]);
      }
    });
  }, []);

  const isAlias = useAppSelector((state) => state.filters.searchFilters.isAlias);

  return (
    <>
      <div className="org-suggest-container">
        <h2 className="org-suggest-title">Did you mean?</h2>
        <p className="org-suggest-count">
          {index + 1} / {totalPage}
        </p>
        <h3 className="org-suggest-org-name">{name}</h3>
        <Accordion multiple={true} collapsible={false} defaultOpenItems={"panel"}>
          {/* Name */}
          {nameStock.length > 0 && (
            <AccordionItem value={"panel"}>
              <AccordionHeader className="org-suggest-accordion">{isAlias ? "Stock Symbol" : "Name"}</AccordionHeader>
              <AccordionPanel>
                {nameStock &&
                  nameStock.map((item: any, index: number) => {
                    return (
                      <IndividualOrgSuggestion
                        key={index}
                        index={index}
                        searchFieldName={isAlias ? "StockSymbol" : "Name"}
                        item={item}
                        displayName={item.displayName}
                        name={name}
                        selectedSuggestions={selectedSuggestions}
                        setSelectedSuggestions={setSelectedSuggestions}
                      />
                    );
                  })}
              </AccordionPanel>
            </AccordionItem>
          )}
          {tagsISIN.length > 0 && (
            <AccordionItem value={"panel"}>
              <AccordionHeader className="org-suggest-accordion">{isAlias ? "ISIN" : "Tags"}</AccordionHeader>
              <AccordionPanel>
                {tagsISIN &&
                  tagsISIN.map((item: any, index: number) => {
                    return (
                      <IndividualOrgSuggestion
                        key={index}
                        index={index}
                        searchFieldName={isAlias ? "Isin" : "Tag"}
                        item={item}
                        displayName={item.displayName}
                        name={name}
                        selectedSuggestions={selectedSuggestions}
                        setSelectedSuggestions={setSelectedSuggestions}
                      />
                    );
                  })}
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </div>
      <div className="org-suggest-footer">
        {/* Back*/}
        <button
          className={index === 0 ? "org-suggest-nav-btn btn-disabled" : "org-suggest-nav-btn"}
          disabled={index === 0 ? true : false}
          onClick={() => {
            if (index <= totalPage) {
              setSuggestionsPage(suggestionsPage - 1);
            }
          }}
        >
          <span className="org-suggest-nav-icon">{`<`}</span>
        </button>

        {/* Apply */}
        <PrimaryButton
          text="Apply"
          onClick={() => {
            handleAppliedBulkSearch();
          }}
        />

        {/* Cancel */}
        <DefaultButton
          onClick={() => {
            discardDYMSuggestion();
            setLoading(false);
            setSuggestionsPage(0);
            setDidYouMeanNeeded(false);
            dispatch(resetSelectedSuggestions());
          }}
          text="Cancel"
        />

        {/* Next */}
        <button
          className={index === totalPage - 1 ? "org-suggest-nav-btn btn-disabled" : "org-suggest-nav-btn"}
          disabled={index === totalPage - 1 ? true : false}
          onClick={() => {
            if (index <= totalPage) {
              setSuggestionsPage(suggestionsPage + 1);
            }
          }}
        >
          <span className="org-suggest-nav-icon">{`>`}</span>
        </button>
      </div>
    </>
  );
};

export default OrgSuggestions;
