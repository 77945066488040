import { IDialogContentStyles } from "@fluentui/react";

export const dialogStyles: Partial<IDialogContentStyles> = {
  header: { padding: "0px", margin: "0px", backgroundColor: "green", width: "0px", height: "0px" },
};
export const PivotStyles:any={
  
    link: {
      margin: "5px",
      marginBottom: "10px",
      height: "25px",
      width: "auto",
      color: "teal",
      border: "1px solid grey",
      borderRadius: "8px",
    },
   
    linkIsSelected: {
      selectors: {
        ":before": {
          height: "0px",
        },
      },
    },
  
}
export const metaDataType:any={
Name:"Name",
StockSymbol:"StockSymbol",
Tag:"Tag",
Isin:"Isin",
}
export const selectedTabValue:any={
  Name:"Name",
  StockSymbol:"StockSymbol",
  Tag:"Tag",
  Isin:"ISIN",
  font:"13px",
  }