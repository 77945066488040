/* global Excel */
import { nanoid } from "nanoid";
import { Base64Image } from "../ImageInsertion.Interfaces";

const DefaultImageWidthPts = 180; // 2.5 inches
const DefaultPadding = 10;

function pixelsToPoints(pixel: number) {
  return pixel * 0.75;
}

export async function insertImagesIntoDocument(base64Images: Base64Image[]) {
  Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const activeCell = context.workbook.getActiveCell();

    activeCell.load("top,left");
    await context.sync();

    let top = activeCell.top;

    for (const base64Image of base64Images) {
      const image = sheet.shapes.addImage(base64Image.data);

      image.name = nanoid();
      image.left = activeCell.left;
      image.top = top;

      // Calculate the scaled height to maintain the aspect ratio
      const aspectRatio = base64Image.height / base64Image.width;
      const imageHeightInPoints = aspectRatio * DefaultImageWidthPts;

      // Set the image width to the default width and scale the height
      image.width = DefaultImageWidthPts;
      image.height = imageHeightInPoints;

      // Calculate the next top position based on the scaled height
      top += imageHeightInPoints+DefaultPadding;
    }

    return await context.sync();
  });
}

export async function findMinimumImageWidth() {
  return DefaultImageWidthPts;
}
