/* global location */

export function constructAbsoluteUrl(endpoint: string) {
  const url = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + endpoint;

  return url;
}

export async function getTenantId() {
  if (location.hostname !== "localhost") {
    let addInSubdomain = location.hostname.split(".")[0];
    let tenantId = addInSubdomain.split("-");
    return tenantId[tenantId.length - 1];
  } else {
    return "bae9d55d";
  }
  // return "wl1"
}
