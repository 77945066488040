/* global PowerPoint, Office, console */
import { Base64Image, Dimensions, GridDimensions, ImageInGrid } from "../ImageInsertion.Interfaces";
import { paddingForRetainShapeConfig, lineInsertionConfig } from "../../../config/addinConfig";
import { circleInsertion } from "../ShapeInsertion/Circle";
import { useSelector } from "react-redux";
const DefaultImageWidthPts = 180; // 2.5 inches
let isShapeLine = false;

export async function getInsertableSelectedShape(context: PowerPoint.RequestContext): Promise<PowerPoint.Shape | null> {
  const shapes = context.presentation.getSelectedShapes();
  shapes.load("items");
  await context.sync();

  if (shapes.items.length > 0) {
    const shape = shapes.items[0];

    if (shape.name.includes("Straight" && "Connector")) {
      isShapeLine = true;
    }

    return shape;
  }

  return null;
}

async function findInsertShapeDimensions(): Promise<Dimensions | null> {
  return PowerPoint.run(async (context) => {
    const shape = await getInsertableSelectedShape(context);
    if (shape) {
      return {
        name: shape.name,
        width: shape.width,
        height: shape.height,
        left: shape.left,
        top: shape.top,
      };
    } else {
      return null;
    }
  });
}

async function deleteSelectedShape() {
  return PowerPoint.run(async (context) => {
    const shape = await getInsertableSelectedShape(context);
    if (shape) {
      // Important to do deletes before changing the selected data, otherwise this reference is invalid
      shape.delete();
      await context.sync();
    }
  });
}

async function insertIntoOfficeDocumentAsync(
  base64Data: string,
  options: Office.SetSelectedDataOptions
): Promise<void> {
  return new Promise((resolve, reject) => {
    Office.context.document.setSelectedDataAsync(base64Data, options, function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        reject(asyncResult.error?.message);
      } else {
        resolve();
      }
    });
  });
}

function pixelsToPoints(pixel: number) {
  return pixel * 0.75;
}

function pointsToPixels(point: number) {
  return point / 0.75;
}

export default async function insertImagesIntoDocument(
  base64Images: Base64Image[],
  insertGridDimensions: GridDimensions,
  alignment: string,
  isRetainShapeSelected: boolean,
  isDistributeHorizontallySelected: boolean,
  logoPadding: number
) {
  let isClampedByHeight = false;
  const insertShape = await findInsertShapeDimensions();

  if (insertShape) {
    if (isRetainShapeSelected === false) {
      await deleteSelectedShape();
    }

    let defaultSpacingPoints = 5;

    let logosMinimumSpacingPercent = 6;
    let heightBasedLogoSpacing = (logosMinimumSpacingPercent / 100) * insertShape.height;
    let widthBasedLogoSpacing = (logosMinimumSpacingPercent / 100) * insertShape.width;
    let logoSpacingPointsToUse = Math.min(heightBasedLogoSpacing, widthBasedLogoSpacing);
    let cellPaddingPts =
      logoSpacingPointsToUse > defaultSpacingPoints
        ? defaultSpacingPoints + logoPadding
        : logoSpacingPointsToUse + logoPadding;
    if (isShapeLine) {
      cellPaddingPts =
        widthBasedLogoSpacing > defaultSpacingPoints ? defaultSpacingPoints + logoPadding : widthBasedLogoSpacing;
    }

    let borderMinimumSpacingPercent = 4;
    let heightBasedBorderSpacing = (borderMinimumSpacingPercent / 100) * insertShape.height;
    let widthBasedBorderSpacing = (borderMinimumSpacingPercent / 100) * insertShape.width;
    let borderSpacingPointsToUse = Math.min(heightBasedBorderSpacing, widthBasedBorderSpacing);
    let borderPaddingPts =
      borderSpacingPointsToUse > defaultSpacingPoints ? defaultSpacingPoints : borderSpacingPointsToUse;

    if (insertShape.name.includes("Oval")) {
      const cellPadding = cellPaddingPts - logoPadding;

      await circleInsertion(
        isRetainShapeSelected,
        insertShape,
        cellPadding,
        logoPadding,
        insertGridDimensions,
        base64Images,
        insertIntoOfficeDocumentAsync
      );
    } else {
      const shapePadding =
        isRetainShapeSelected && !isShapeLine ? (logoPadding > 0 ? cellPaddingPts : borderPaddingPts) : 0; // Padding value
      const offsetLeft = insertShape.left + shapePadding;
      const offsetTop = insertShape.top + shapePadding;
      const insertedShapeWidth = insertShape.width - 2 * shapePadding;
      const insertedShapeHeight = insertShape.height - 2 * shapePadding;
      const usableShapeWidth = insertedShapeWidth - cellPaddingPts * (insertGridDimensions.columns - 1);
      const maxCellWidth = usableShapeWidth / insertGridDimensions.columns;
      const usableShapeHeight =
        insertedShapeHeight +
        (isShapeLine ? lineInsertionConfig.addedHeight : 0) -
        cellPaddingPts * (insertGridDimensions.rows - 1);
      const maxCellHeight = usableShapeHeight / insertGridDimensions.rows;

      // This algorithm is to equally distribute whitespace between logos
      const base64ImagesInGrid: ImageInGrid[] = [];

      // Loop through all the logos to find the maximum widths of each item we can fit. This gives us the amount of whitespace we have to deal with
      for (const base64Image of base64Images) {
        let calculatedWidth = null;

        // Clamp to cell dimensions
        // Figure out if we're clamping by width or height
        if (base64Image.height / base64Image.width < maxCellHeight / maxCellWidth) {
          calculatedWidth = maxCellWidth;
        } else {
          calculatedWidth = +(maxCellHeight * base64Image.width) / base64Image.height;
          isClampedByHeight = true;
        }

        const usableCellWidth = Math.min(calculatedWidth, pixelsToPoints(base64Image.width));

        base64ImagesInGrid.push({
          width: usableCellWidth,
          base64Image: base64Image,
          imageFound: base64Image.imageFound,
          orgName: base64Image.orgName,
        });
      }

      let imageIndex = 0;

      while (base64ImagesInGrid.length > 0) {
        // Deal with the items a row at a time
        const itemsInRow = base64ImagesInGrid.splice(0, insertGridDimensions.columns);

        // Each row has a different distribution of whitespace
        let totalWidthUsed = itemsInRow.reduce((total, image) => total + image.width, 0);
        // Fill some more whitespace in the last row
        totalWidthUsed += maxCellWidth * (insertGridDimensions.columns - itemsInRow.length);
        const totalWhitespaceAvailable = insertedShapeWidth - totalWidthUsed;
        const horizontalWhitespaceGap = totalWhitespaceAvailable / (insertGridDimensions.columns - 1);

        let nextLeft = offsetLeft;

        for (const base64ImageInGrid of itemsInRow) {
          if (!isDistributeHorizontallySelected && alignment === "center") {
            nextLeft += (maxCellWidth - base64ImageInGrid.width) / 2;
          }

          if (!isDistributeHorizontallySelected && alignment === "right") {
            nextLeft += maxCellWidth - base64ImageInGrid.width;
          }

          const options: Office.SetSelectedDataOptions = {
            coercionType: Office.CoercionType.Image,
            imageLeft: nextLeft,
            imageTop:
              (isShapeLine
                ? isClampedByHeight
                  ? lineInsertionConfig.ClampedByHeightLinePts
                  : lineInsertionConfig.LinePts
                : 0) +
              offsetTop +
              Math.floor(imageIndex / insertGridDimensions.columns) * (cellPaddingPts + maxCellHeight),
          };

          options.imageWidth = base64ImageInGrid.width;

          if (!isDistributeHorizontallySelected && alignment === "center") {
            nextLeft += base64ImageInGrid.width + (maxCellWidth - base64ImageInGrid.width) / 2 + cellPaddingPts;
          }

          if (!isDistributeHorizontallySelected && alignment === "right") {
            nextLeft += base64ImageInGrid.width + cellPaddingPts;
          }

          if (!isDistributeHorizontallySelected && alignment === "left") {
            nextLeft += maxCellWidth + cellPaddingPts;
          }

          if (isDistributeHorizontallySelected) {
            nextLeft += base64ImageInGrid.width + horizontalWhitespaceGap;
          }

          // Clamp to cell dimensions
          if (
            base64ImageInGrid.base64Image.height / base64ImageInGrid.base64Image.width <
            maxCellHeight / maxCellWidth
          ) {
            options.imageWidth = base64ImageInGrid.width;

            // Centralise the logos vertically
            const calculatedHeight =
              (maxCellWidth * base64ImageInGrid.base64Image.height) / base64ImageInGrid.base64Image.width;
            options.imageTop! += (maxCellHeight - calculatedHeight) / 2;
          } else {
            options.imageHeight = maxCellHeight;
          }

          await insertIntoOfficeDocumentAsync(base64ImageInGrid.base64Image.data, options);

          imageIndex++;
        }
      }

      isShapeLine = false;
      isClampedByHeight = false;
    }
  } else {
    // Inserting images into the document, every image is a default width.
    // Images not constrained to page dimensions
    const offsetLeft = 0;
    const offsetTop = 0;
    let imageIndex = 0;
    const maxCellWidth = DefaultImageWidthPts;
    const maxCellHeight = DefaultImageWidthPts;
    const cellPaddingPts = 10;

    for (const base64Image of base64Images) {
      let options: Office.SetSelectedDataOptions = {
        coercionType: Office.CoercionType.Image,
        imageLeft: offsetLeft + (imageIndex % insertGridDimensions.columns) * (cellPaddingPts + maxCellWidth),
        imageTop: offsetTop + Math.floor(imageIndex / insertGridDimensions.columns) * (cellPaddingPts + maxCellHeight),
      };

      if (base64Image.height / base64Image.width < maxCellHeight / maxCellWidth) {
        options.imageWidth = maxCellWidth;
      } else {
        options.imageHeight = maxCellHeight;
      }

      await insertIntoOfficeDocumentAsync(base64Image.data, options);

      imageIndex++;
    }
  }
}

export async function findMinimumImageWidth(insertGridDimensions: GridDimensions) {
  const insertShape = await findInsertShapeDimensions();

  if (insertShape) {
    const maxCellWidth = insertShape.width / insertGridDimensions.columns;

    return pointsToPixels(maxCellWidth);
  } else {
    return DefaultImageWidthPts;
  }
}
