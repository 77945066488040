const wlConfig = {
  apiEndpoint: "https://api.wl-qa-logostore.com",
  configurationEndpoint: "https://api4internal.wl-qa-logostore.com",
  region: "us-east-1",
  userPoolId: "us-east-1_NV5lgzG7J",
  cognitoDomain: "wl-qa-logostore.auth.us-east-1.amazoncognito.com",
  scope: ["email", "profile", "openid"],
  responseType: "code",
  version: "February 2025.2",
};
export default wlConfig;
