/* global Office */
export function isExcel(): boolean {
  return Office.context.host === Office.HostType.Excel;
}

export function isWord(): boolean {
  return Office.context.host === Office.HostType.Word;
}

export function isPowerPoint(): boolean {
  return Office.context.host === Office.HostType.PowerPoint;
}

export function hasFeatureGridInsert(): boolean {
  return isPowerPoint();
}
