import React from "react";
import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import "./Styles/support.css";
import { TicketResponse } from "./SupportPageInterfaces";
import { useAppSelector } from "../../Redux/store/store";

const SupportTicketSubmission = ({ data, success }: TicketResponse) => {
  const authCheck = useAppSelector((state) => state.authStatus.isAuthenticated);
  const history = useHistory();
  function onClickOk() {
    if (authCheck === true) {
      history.push("/search");
    } else {
      history.push("/");
    }
  }

  return (
    <Dialog
      hidden={!success}
      onDismiss={() => history.push("/")}
      dialogContentProps={{
        type: DialogType.normal,
        title: <p className="support-dialog-title">Support ticket submitted</p>,
        closeButtonAriaLabel: "Close",
        subText: data?.reference,
      }}
    >
      <DialogFooter className="support-dialog-footer">
        <PrimaryButton onClick={() => onClickOk()} text="Ok" />
      </DialogFooter>
    </Dialog>
  );
};

export default SupportTicketSubmission;
