export const paddingForRetainShapeConfig = {
    padding: 10,
  };
  
  export const lineInsertionConfig = {
    addedHeight: 75,
  
    ClampedByHeightLinePts: -90,
  
    LinePts: -75,
  };
  
  export const imageNotFoundPlaceHolderConfig = {
    backgroundColor: "yellow",
    color: "black",
    font: "40px Arial",
  };
  