import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { amplifyConfiguredStatusRedux, authStatusRedux } from "../redux.interfaces";

const initialState: amplifyConfiguredStatusRedux = {
  isAmplifyConfigured: false,
  bannerLogoUrl: "",
  hasLongAuthSession:false,
};

export const AmplifyConfiguredStatusSlice = createSlice({
  name: "authStatus",
  initialState,
  reducers: {
    UpdateIsAmplifyConfiguredStatus: (state, action: PayloadAction<{ isAmplifyConfigured: boolean }>) => {
      state.isAmplifyConfigured = action.payload.isAmplifyConfigured;
    },
    UpdateBannerLogoUrl: (state, action: PayloadAction<{ bannerLogoUrl: string }>) => {
      state.bannerLogoUrl = action.payload.bannerLogoUrl;
    },
    UpdateLongAuthSession: (state, action: PayloadAction<{ hasLongAuthSession: boolean }>) => {
      state.hasLongAuthSession = action.payload.hasLongAuthSession;
    },
  },
});

export default AmplifyConfiguredStatusSlice.reducer;
export const { UpdateIsAmplifyConfiguredStatus } = AmplifyConfiguredStatusSlice.actions;
export const { UpdateBannerLogoUrl } = AmplifyConfiguredStatusSlice.actions;
export const { UpdateLongAuthSession } = AmplifyConfiguredStatusSlice.actions;
