import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { searchFiltersRedux } from "../redux.interfaces";

export const searchFilters: searchFiltersRedux = {
  isLatest: false,
  type: "logo",
  format: "any",
  // opacity: "any",
  colour: "standard",
  sort: "searchOrder",
  isAsc: true,
  count: [],
  altCount: [],
  isBest: true,
  isAlias: false,
  status:"",
  // theme:"any",
};

export const FilterSlice = createSlice({
  name: "filters",
  initialState: {
    searchFilters,
  },
  reducers: {
    updateIsLatest: (state) => {
      state.searchFilters.isLatest = !state.searchFilters.isLatest;
      state.searchFilters.count.includes("isLatest")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "isLatest"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.isLatest) {
        case false:
          break;
        case true:
          state.searchFilters.count.push("isLatest");
          break;
        default:
          break;
      }
    },
    updateType: (state, action: PayloadAction<{ newType: string }>) => {
      state.searchFilters.type = action.payload.newType;
      state.searchFilters.count.includes("type")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "type"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.type) {
        case "logo":
          break;
        case "all":
        case "icon":
          state.searchFilters.count.push("type");
          break;
        default:
          break;
      }
    },
    updateFormat: (state, action: PayloadAction<{ newFormat: string }>) => {
      state.searchFilters.format = action.payload.newFormat;
      state.searchFilters.count.includes("format")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "format"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.format) {
        case "any":
          break;
        case "png":
        case "svg":
        case "jpeg":
          state.searchFilters.count.push("format");
          break;
        default:
          break;
      }
    },
    // updateOpacity: (state, action: PayloadAction<{ newOpacity: string }>) => {
    //   state.searchFilters.opacity = action.payload.newOpacity;
    //   state.searchFilters.count.includes("opacity")
    //     ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "opacity"))
    //     : (state.searchFilters.count = state.searchFilters.count);
    //   switch (state.searchFilters.opacity) {
    //     case "any":
    //       break;
    //     case "transparent":
    //     case "opaque":
    //       state.searchFilters.count.push("opacity");
    //       break;
    //     default:
    //       break;
    //   }
    // },
    updateColour: (state, action: PayloadAction<{ newColor: string }>) => {
      state.searchFilters.colour = action.payload.newColor;
      state.searchFilters.count.includes("colour")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "colour"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.colour) {
        case "standard":
          break;
        case "any":
        case "white":
          state.searchFilters.count.push("colour");
          break;
        default:
          break;
      }
    },
    // updateTheme: (state, action: PayloadAction<{ newTheme: string }>) => {
    //   state.searchFilters.theme = action.payload.newTheme;
    //   state.searchFilters.count.includes("theme")
    //     ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "theme"))
    //     : (state.searchFilters.count = state.searchFilters.count);
    //   switch (state.searchFilters.theme) {
    //     case "any":
    //       break;
    //     case "dark":
    //     case "light":
    //       state.searchFilters.count.push("theme");
    //       break;
    //     default:
    //       break;
    //   }
    // },
    updateSort: (state, action: PayloadAction<{ newSort: string }>) => {
      state.searchFilters.sort = action.payload.newSort;
      state.searchFilters.count.includes("sort")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "sort"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.sort) {
        case "searchOrder":
          break;

        case "alphabetically":
          state.searchFilters.count.push("sort");
          break;
        default:
          break;
      }

      state.searchFilters.altCount.includes("sort")
        ? (state.searchFilters.altCount = state.searchFilters.altCount.filter((item) => item !== "sort"))
        : (state.searchFilters.altCount = state.searchFilters.altCount);
      switch (state.searchFilters.sort) {
        case "searchOrder":
          break;

        case "alphabetically":
          state.searchFilters.altCount.push("sort");
          break;
        default:
          break;
      }
    },
    updateIsAsc: (state) => {
      state.searchFilters.isAsc = !state.searchFilters.isAsc;
      state.searchFilters.count.includes("isAsc")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "isAsc"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.isAsc) {
        case true:
          break;
        case false:
          state.searchFilters.count.push("isAsc");
          break;
        default:
          break;
      }

      state.searchFilters.altCount.includes("isAsc")
        ? (state.searchFilters.altCount = state.searchFilters.altCount.filter((item) => item !== "isAsc"))
        : (state.searchFilters.altCount = state.searchFilters.altCount);
      switch (state.searchFilters.isAsc) {
        case true:
          break;
        case false:
          state.searchFilters.altCount.push("isAsc");
          break;
        default:
          break;
      }
    },
    updateIsBest: (state) => {
      state.searchFilters.isBest = !state.searchFilters.isBest;
      state.searchFilters.count.includes("isBest")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "isBest"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.isBest) {
        case true:
          break;
        case false:
          state.searchFilters.count.push("isBest");
          break;
        default:
          break;
      }

      state.searchFilters.altCount.includes("isBest")
        ? (state.searchFilters.altCount = state.searchFilters.altCount.filter((item) => item !== "isBest"))
        : (state.searchFilters.altCount = state.searchFilters.altCount);
      switch (state.searchFilters.isBest) {
        case true:
          break;
        case false:
          state.searchFilters.altCount.push("isBest");
          break;
        default:
          break;
      }
    },
    updateIsAlias: (state) => {
      state.searchFilters.isAlias = !state.searchFilters.isAlias;
      state.searchFilters.count.includes("isAlias")
        ? (state.searchFilters.count = state.searchFilters.count.filter((item) => item !== "isAlias"))
        : (state.searchFilters.count = state.searchFilters.count);
      switch (state.searchFilters.isAlias) {
        case true:
          state.searchFilters.count.push("isAlias");
          break;
        case false:
          break;
        default:
          break;
      }

      state.searchFilters.altCount.includes("isAlias")
        ? (state.searchFilters.altCount = state.searchFilters.altCount.filter((item) => item !== "isAlias"))
        : (state.searchFilters.altCount = state.searchFilters.altCount);
      switch (state.searchFilters.isAlias) {
        case true:
          state.searchFilters.altCount.push("isAlias");
          break;
        case false:
          break;
        default:
          break;
      }
    },
    reset: (state) => {
      const originalFilters = Object.assign(searchFilters);
      state.searchFilters = originalFilters;
    },
  },
});

export default FilterSlice.reducer;
export const {
  updateIsLatest,
  updateType,
  updateFormat,
  // updateOpacity,
  updateColour,
  // updateTheme,
  updateSort,
  updateIsAsc,
  updateIsBest,
  updateIsAlias,
  reset,
} = FilterSlice.actions;
