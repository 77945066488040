import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";

const AddLogoRequestConfirmation = ({
  showSuccess,
  handleSubmitConfirmation,
  submittedOrg,
  addLogoRequestResponse,
}: {
  showSuccess: boolean;
  handleSubmitConfirmation: () => void;
  submittedOrg: string;
  addLogoRequestResponse: any;
}) => {
  const ConfirmationMessage =
    addLogoRequestResponse.data.reference === ""
      ? `Your new logo for '${submittedOrg}' is currently being processed and will be available for use in the next few minutes.`
      : addLogoRequestResponse.data.reference;
  const submitDialogContentProps = {
    type: DialogType.normal,
    // title:"Upload Confirmation",
    subText: ConfirmationMessage,
  };
  return (
    <>
      <Dialog hidden={!showSuccess} dialogContentProps={submitDialogContentProps}>
        <DialogFooter>
          <PrimaryButton onClick={() => handleSubmitConfirmation()} text="Ok" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default AddLogoRequestConfirmation;
