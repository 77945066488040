import React, { useEffect, useState } from "react";
import { Image as FluentImage, TooltipHost, getTheme, memoizeFunction, mergeStyles } from "@fluentui/react";
import { Photo } from "../ImageSearch.Interfaces";
import "./Styles/PhotoCell.css";
import { Carousel } from "react-bootstrap";
import { getClassNames } from "../PhotoGrid/Styles/PhotoGridStyles";


const theme = getTheme();

// Styling requires use of theme, won't work if exported
const photoCellClass = memoizeFunction((isToggled: boolean) => {
  return mergeStyles({
    aspectRatio: "1",
    position: "relative",
    display: "flex",
    padding: "3px",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.5)",
    border: isToggled ? `2px solid ${theme.palette.themePrimary}` : "2px solid white",
    overflow: "visible",
  });
});

const orderNumberClass = mergeStyles({
  position: "fixed",
  top: "-10px",
  right: "10px",
  paddingTop: "2px",
  backgroundColor: theme.palette.themePrimary,
  color: theme.palette.white,
  fontSize: "13px",
  borderRadius: "50%",
  minWidth: "24px",
  maxWidth: "auto",
  minHeight: "23px",
  maxHeight: "auto",
  lineHeight: "18px",
  textAlign: "center",
});

export function PhotoCell({
  photo,
  onClick,
  selectedPhotos,
  currentImageOnTheGrid,
  setCurrentImageOnTheGrid
}: {
  photo: Photo[];
  onClick: (photo: Photo) => void;
  selectedPhotos: Photo[];
  currentImageOnTheGrid:any[];
  setCurrentImageOnTheGrid:(currentImageOnTheGrid:any)=>void
}) {
  const { latestRibbon, photoGridPhotoClass, photoGridTransparentBackgroundClass } = getClassNames();
  const multipleImages = photo.length > 1;

  function handleSelect(value: number) {
    const currentImage = photo[value];
    const updatedArray = currentImageOnTheGrid.map((item:any, idx: number) => {
      if (item.orgName === currentImage.orgName) {
        return (currentImageOnTheGrid[idx] = currentImage);
      }
      return item;
    });
    setCurrentImageOnTheGrid(updatedArray);
    if (selectedPhotos.length > 0) {
      selectedPhotos.map((item:Photo) => {
        if (item.orgName === currentImage.orgName) {
          onClick(item);
        }
      });
    }
  }

  return (
    <>
      <div className="carousel-container">
        <Carousel interval={null} indicators={multipleImages} controls={multipleImages} onSelect={handleSelect}>
          {photo.map((item: Photo) => {
            const foundInSelectedIndex = selectedPhotos.findIndex((p:Photo) => p.id === item.id);
            const orderNumber = foundInSelectedIndex !== -1 ? foundInSelectedIndex + 1 : undefined;
            const isLatest = item.isLatest;
            const logoStatus = item.status;
            const isTransparent = item.opacity === "transparent" || item.format === "svg";
            const isSelected = selectedPhotos.find((p: Photo) => p.id === item.id) !== undefined;
            return (
              <Carousel.Item key={item.id}>
                <div
                  className={isTransparent ? photoGridTransparentBackgroundClass : photoGridPhotoClass}
                  key={item.id}
                >
                  {isLatest && (
                    <>
                      <div className={latestRibbon}>
                        <span>LATEST</span>
                      </div>
                      <div className="pg-ribbon-wrap" />
                    </>
                  )}
                  {logoStatus === "Awaiting Qc" && (
                    <>
                      <div className={latestRibbon}>
                        <span>AWAITING QC</span>
                      </div>
                      <div className="pg-ribbon-wrap" />
                    </>
                  )}
                  <TooltipHost content={`${item.orgName}.${item.format}`}>
                    <div
                      className={photoCellClass(isSelected)}
                      onClick={() => {
                        onClick(item);
                      }}
                    >
                      <FluentImage
                        className={multipleImages ? "pc-carousel-img" : "pc-img"}
                        onDragStart={(ev) => ev.preventDefault()}
                        onDrag={(ev) => ev.preventDefault()}
                        width="100%"
                        src={item.previewUrl}
                      />
                      {orderNumber !== undefined && (
                        <p className={orderNumberClass} data-testid="orderNumber">
                          {orderNumber}
                        </p>
                      )}
                    </div>
                  </TooltipHost>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}
