import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";
import { dialogContentStyles } from "./Styles/AddLogoStyles";

const AddLogoRequestError = ({
  error,
  setError,
  errorMsg,
  errorStatusCode,
}: {
  error: boolean;
  setError: () => void;
  errorMsg: any;
  errorStatusCode: any;
}) => {

  let duplicateBrandValidationMsg;
  if(errorStatusCode==400){
    duplicateBrandValidationMsg = errorMsg.detailedMessages.shift();
  }
  const domains = errorMsg.detailedMessages;
  const addLogoRequestFailedDialogContentProps = {
    type: DialogType.normal,
    title:errorStatusCode === 400 ?"Invalid Request":"Request Failed.",
    subText:
      errorStatusCode === 400
        ? duplicateBrandValidationMsg
        : `An error occurred while adding the logo. Please try again later.`,
    // subText: `An error occurred while adding the logo. Please try again later.`,
    styles: dialogContentStyles,
  };

  return (
    <>
      <Dialog  hidden={!error} dialogContentProps={addLogoRequestFailedDialogContentProps}>
      {errorStatusCode==400 &&
      <div style={{ overflowY: "auto", maxHeight: "200px" }}>
        <ul>
          {domains.map((domain: any) => (
            <li className="domainList">{domain}</li>
          ))}
        </ul>
        </div>}
        <DialogFooter>
          <PrimaryButton onClick={() => setError()} text="Close" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default AddLogoRequestError;
