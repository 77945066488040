import { IModalProps } from "@fluentui/react";

export const dialogContentStyles = {
  title: {
    fontSize: "18px",
    textAlign: "center",
  },
  subText: {
    fontSize: "14px",
    textAlign: "center",
    padding: "0px",
  },
};

export const footerStyles = {
  actions: {
    display: "flex",
    justifyContent: "center",
  },
};

export const requestLogoButtonStyles = {
  root: {
    whiteSpace: "nowrap",
    padding:"5px",
    // paddingLeft:"10px",
  }
};

export const AddLogoButtonStyles = {
  root: {
    whiteSpace: "nowrap",
  }
};
export const missingOrgsModalStyles: IModalProps = {
  styles: {
    main: {
      minHeight: "inherit",
      maxHeight: "500px",
    },
  },
};
