import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

async function getSessionData() {
  // const user = await Auth.currentSession();
  // return user;
  try {
    const user = await Auth.currentSession();
    return user;
  } catch (error: any) {
    localStorage.clear();
    window.location.reload();
    return null;
  }
}

export const isUserLoggedIn = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export { getSessionData };
