import { PrimaryButton, DefaultButton, TextField, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "./Styles/PasswordVerification.css";
import { error } from "./PasswordVerifiction.Interfaces";

const PasswordVerification = ({ email }: { email: string }) => {
  const [apiResponse, setApiResponse] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<error>({ message: "", title: "" });
  const [successMessage, setSuccessMessage] = useState<string>("");
  const history = useHistory();

  const handleAuthError = (error: any) => {
    switch (error.code) {
      case "UsernameExistsException":
        return {
          message: "An error is encountered while registering your account. Please contact service desk.",
          title: "User Already Exist",
        };
      case "InvalidPasswordException":
        return { message: error.message, title: "Invalid Password" };
      default:
        return { message: error.message, title: error.code };
    }
  };

  const confirmUser = async (values: { email: string; confirmationCode: string; password: string }) => {
    try {
      const user = await Auth.forgotPasswordSubmit(values.email, values.confirmationCode, values.password);
      if (user === "SUCCESS") {
        setSuccessMessage("User successfully registered");
      }
    } catch (error) {
      let errorMessage = handleAuthError(error);
      setErrorMessage(errorMessage);
    }
    setApiResponse(true);
  };

  const formik = useFormik({
    initialValues: {
      email: email,
      confirmationCode: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required."),
      confirmationCode: Yup.string()
        .required("Code is required.")
        .matches(/^[0-9]+$/, "Must be only digits.")
        .min(4, "Must be 4 digits.")
        .max(6, "Must be 6 digits."),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters.")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
        .matches(/\d/, "Password must contain at least one number.")
        .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Password must contain at least one special character.")
        .required("Password is required."),
      confirmPassword: Yup.string()
        .required("Confirm password is required.")
        .oneOf([Yup.ref("password"), ""], "Password and Confirm password must match."),
    }).shape({}),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      confirmUser(values);
    },
  });

  return (
    <div className="page">
      <h3 className="page-slogan">Sign in to start adding logos.</h3>
      <h3 className="page-heading">Confirmation code</h3>

      <form onSubmit={formik.handleSubmit}>
        {/* Email */}
        <label className="form-field-label">
          <span className="asterisk-red">*</span>Email
        </label>
        <TextField className="" {...formik.getFieldProps("email")} />
        <div className="validation-error">{formik.touched.email && formik.errors.email}</div>

        {/* Code */}
        <label className="form-field-label form-field-spacing">
          <span className="asterisk-red">*</span>Code
        </label>
        <TextField className="" {...formik.getFieldProps("confirmationCode")} />
        <div className="validation-error">{formik.touched.confirmationCode && formik.errors.confirmationCode}</div>

        {/* Password */}
        <label className="form-field-label form-field-spacing">
          <span className="asterisk-red">*</span>Password
        </label>
        <TextField type="password" className="" {...formik.getFieldProps("password")} />
        <div className="validation-error">{formik.touched.password && formik.errors.password}</div>

        {/* Confirm Password */}
        <label className="form-field-label form-field-spacing">
          <span className="asterisk-red">*</span>Confirm password
        </label>
        <TextField type="password" className="" {...formik.getFieldProps("confirmPassword")} />
        <div className="validation-error">{formik.touched.confirmPassword && formik.errors.confirmPassword}</div>

        <div className="btns-container">
          <div>
            <PrimaryButton type="submit" text="Submit" />
          </div>
          <div>
            <DefaultButton text="Cancel" onClick={() => history.push("/")} />
          </div>
        </div>
      </form>

      <div className="vp-error-message">
        {errorMessage.message != "" && <div className="validation-error">Error: {errorMessage.message}</div>}
      </div>

      {apiResponse && successMessage != "" && (
        <>
          <Dialog
            hidden={!apiResponse}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Password changed successfully.",
            }}
          >
            <DialogFooter className="vp-footer">
              <PrimaryButton onClick={() => history.push("/")} text="Ok" />
            </DialogFooter>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default PasswordVerification;
