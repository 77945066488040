/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import { PrimaryButton, TextField, Stack, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { InternalDomains } from "../../Util/Constants";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../Redux/store/store";
import { UpdateAuthStatus } from "../../Redux/features/authStatus";
import { NativeLoginProps } from "./NativeLogin.Interfaces";
import { signInO365 } from "../../Util/federatedLoginHelper";
import "./Styles/NativeLogin.css";

const NativeLogin = ({ setError, isLoading, setIsLoading }: NativeLoginProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userType, setUserType] = useState<"internal" | "external" | undefined>(undefined);

  async function federatedLogin() {
    setIsLoading(true);
    setError(undefined);

    try {
      await signInO365(checkAuth, setError);
    } catch (error: any) {
      setError("Error signing in " + error);
      console.error("error signing in", error);
    }
    setIsLoading(false);
  }

  async function usernamePasswordLogin(username: string, password: string) {
    setIsLoading(true);

    try {
      await Auth.signIn(username, password);
      history.push("/search");
    } catch (error: any) {
      setError("Invalid username and/or password.");
      console.error("error signing in", error);
    }

    setIsLoading(false);
  }

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userSession = await Auth.currentSession();

      if (userSession?.getIdToken()?.payload["email"]) {
        const email = userSession?.getIdToken()?.payload["email"];
        const domain = email.split("@")[1];
        if (InternalDomains.includes(domain)) {
          setUserType("internal");
        } else {
          setUserType("external");
        }
      }
      dispatch(UpdateAuthStatus({ isAuthenticated: true }));
      history.push("/search");
    } catch (err: any) {
      setUserType(undefined);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Username is required.")
        .test("not-empty", "username cannot be empty.", function (value) {
          console.log(value, "Username");
          return value !== undefined && value.trim().length > 0;
        }),
      password: Yup.string()
        .required("Password is required.")
        .test("not-empty", "Password cannot be empty.", function (value) {
          return value !== undefined && value.trim().length > 0;
        }),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      // console.log("click", values);
      // history.push("/search");
      usernamePasswordLogin(values.username, values.password);
    },
  });
  useEffect(() => {
    // if (formik.errors.username && formik.touched.username) {
    //   setError("Invalid username and/or password.");
    // }
    // if (formik.errors.password && formik.touched.password) {
    //   setError("Invalid username and/or password.");
    // }
    if (!formik.errors.username && !formik.errors.password) {
      setError(undefined);
    }
  }, [formik.errors, formik.touched]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField type="email" label="Username" {...formik.getFieldProps("username")} />
        {formik.touched.username && formik.errors.username ? (
          <div className="error-Msg fs-9">{formik.errors.username}</div>
        ) : null}
        {/* {formik.touched.username && formik.errors.username ? (
          <div className="text-danger fs-8">{formik.errors.username}</div>
        ) : null} */}
        <TextField type="password" canRevealPassword label="Password" {...formik.getFieldProps("password")} />
        {formik.touched.password && formik.errors.password ? (
          <div className="error-Msg fs-9">{formik.errors.password}</div>
        ) : null}
        {/* {formik.touched.password && formik.errors.password ? (
          <div className="text-danger fs-8">{formik.errors.password}</div>
        ) : null} */}

        <p className="nl-forgot-password" role="button" onClick={() => history.push("/forgot-password")}>
          Forgot password?
        </p>

        {isLoading && (
          <Spinner
            className="nl-spinner"
            label="Logging in..."
            ariaLive="assertive"
            labelPosition="top"
            size={SpinnerSize.large}
          />
        )}

        {!isLoading && (
          <>
            <div className="nl-btns">
              <PrimaryButton className="nl-sign-in-btn" type="submit">
                Sign in
              </PrimaryButton>
              <PrimaryButton className="nl-sign-in-btn-org" onClick={federatedLogin}>
                Sign in via org
              </PrimaryButton>
            </div>
            <hr />
          </>
        )}
      </form>
    </>
  );
};

export default NativeLogin;
