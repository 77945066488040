import { createTheme, ITheme, PartialTheme } from "@fluentui/react";

export const appTheme: PartialTheme = {
  palette: {
    themePrimary: "#077c82",
    themeSecondary: "#ad9442",
    themeTertiary: "#ad9442",
  },
};

export const actionButtonTheme: ITheme = createTheme({
  palette: {
    themePrimary: "#077c82",
    themeDarkAlt: "#ad9442",
    themeDark: "#ad9442",
  },
});
