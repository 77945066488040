import React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

import { dialogContentStyles,footerStyles,retainShapeValidationErrorPopUpModelProps} from "./Styles/retainShapeValidationErrorPopUpStyles";

export function RetainShapeValidationErrorPopUp({
    setRetainShapeValidationErrorMessagePopUp,
    retainShapeValidationErrorMessagePopUp,
}:{
    setRetainShapeValidationErrorMessagePopUp:any;
    retainShapeValidationErrorMessagePopUp:boolean;
}){

    const dialogContentProps = {
        type: DialogType.normal,
        subText: "Please choose a shape or deselect the Retain Shape option.",
        styles: dialogContentStyles,
      };
    return(
        <>
     
       <Dialog
        hidden={!retainShapeValidationErrorMessagePopUp}
        onDismiss={() => setRetainShapeValidationErrorMessagePopUp(false)}
        dialogContentProps={dialogContentProps}
        modalProps={retainShapeValidationErrorPopUpModelProps}
      >
       

        <DialogFooter styles={footerStyles}>
     
       
          <PrimaryButton
            onClick={() => {
                setRetainShapeValidationErrorMessagePopUp(false);
            }}
            text="Ok"
          />
        </DialogFooter>
      </Dialog>
        </>
    )
}

export default RetainShapeValidationErrorPopUp;