import React, { useState, useEffect, useRef } from "react";
import { SpinButton, Dropdown, ResponsiveMode, Checkbox, MessageBar, MessageBarType } from "@fluentui/react";
import {
  dropdownStyles,
  SpinnerStyles,
  compressImagesCheckBoxStyles,
  retainShapeCheckBoxStyles,
  distributeCheckBoxStyles,
  ColumnSpinnerStyles,
  RowSpinnerStyles,
} from "./Styles/GridOptionsPanelStyles";
import { GridDimensions } from "../../ImageInsertion/ImageInsertion.Interfaces";
import "./Styles/GridOptionsPanel.css";
import { useAppDispatch, useAppSelector } from "../../../Redux/store/store";
import { UpdateCompressImagesInputStatus } from "../../../Redux/features/insertedLogoFormat";
import logoPadding, { UpdateLogoPadding } from "../../../Redux/features/logoPadding";

// dropdown options
const alignmentOptions = [
  { key: "left", text: "Left" },
  { key: "center", text: "Center" },
  { key: "right", text: "Right" },
];

export function GridOptionsPanel({
  itemCount,
  updateGridDimensions,
  alignment,
  setAlignment,
  isRetainShapeSelected,
  setIsRetainShapeSelected,
  setIsShapeSelected,
  isShapeSelected,
  retainShapeValidationErrorMessage,
  isShapeOval,
  isDistributeHorizontallySelected,
  setDistributeHorizontallySelected,
  isShapeNull,
  error,
  setError,
  setIsValid,
}: {
  itemCount: number;
  updateGridDimensions: (dimensions: GridDimensions) => void;
  alignment: string;
  isRetainShapeSelected: boolean;
  setIsRetainShapeSelected: (key: any) => void;
  setAlignment: (key: any) => void;
  setIsShapeSelected: (key: any) => void;
  isShapeSelected: boolean;
  retainShapeValidationErrorMessage: boolean;
  isShapeOval: boolean;
  isDistributeHorizontallySelected: boolean;
  setDistributeHorizontallySelected: (isDistributeHorizontallySelected: boolean) => void;
  isShapeNull: boolean;
  error: any;
  setError: (error: any) => void;
  setIsValid: (isValid: any) => void;
}) {
  const [columns, setColumns] = useState<number>(1);
  const [rows, setRows] = useState<number>(1);
  const [padding, setPadding] = useState<any>(0);
  const [autoCalculate, setAutoCalculate] = useState(true);
  const [isShapeLine, setIsShapeLine] = useState<boolean>(false);
  // const [error, setError] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const isCompressImagesSelected = useAppSelector((state) => state.insertedLogoFormat.logoFormat);
  // check if shape is selected
  function shapeSelection() {
    return PowerPoint.run(async (context) => {
      async function getShape(context: PowerPoint.RequestContext): Promise<PowerPoint.Shape | null> {
        const shapes = context.presentation.getSelectedShapes();
        shapes.load("items");
        await context.sync();

        if (shapes.items.length > 0) {
          const shape = shapes.items[0];
          setIsShapeSelected(true);

          if (shape.name.includes("Straight" && "Connector")) {
            setIsShapeLine(true);
          }

          return shape;
        }

        setIsShapeSelected(false);
        return null;
      }

      getShape(context);
    });
  }

  // check if we should display alignment option
  useEffect(() => {
    shapeSelection();
  }, [columns, isShapeSelected]);

  // When nothing is selected, revert back to auto calculation
  useEffect(() => {
    if (itemCount === 0) {
      setAutoCalculate(true);
      setColumns(1);
      setRows(1);
    }

    if (autoCalculate) {
      const calculated_rows = Math.floor(Math.sqrt(itemCount)) || 1;
      const calculated_columns = Math.ceil(itemCount / calculated_rows) || 1;

      if (isShapeLine) {
        setRows(1);
        setColumns(itemCount);
      } else {
        setColumns(calculated_columns);
        setRows(calculated_rows);
      }
    }
  }, [itemCount, isShapeLine, isShapeOval]);

  useEffect(() => {
    updateGridDimensions({ columns, rows });
  }, [columns, rows]);
  function IsGridDimensionValid(nRows: number, nCols: number, nImageCount: number) {
    // Initialize the flag variable to false.
    let isValid = false;
    if (nRows === 1) {
      if (nRows * nCols === nImageCount) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else {
      // Calculate the minimum number of rows needed to fit all the images in the grid.
      let rIndex = 1;
      while (rIndex * nCols < nImageCount) {
        rIndex++;
      }
      // If the actual number of rows is less than or equal to the calculated minimum, set the flag variable to true.
      if (nRows <= rIndex) {
        isValid = true;
      } else {
        // Otherwise, set the flag variable to false.
        isValid = false;
      }
    }
    // Return the flag variable indicating if the grid dimensions are valid or not.
    return isValid;
  }
  function validateItemCount(calculatedColumns: number, calculatedRows: number) {
    const itemCapacity = calculatedColumns * calculatedRows;

    // Checks if the given grid dimensions are valid based on the number of images.
    let isValidScenario = IsGridDimensionValid(calculatedRows, calculatedColumns, itemCount);
    setIsValid(isValidScenario);
    if (itemCount > itemCapacity) {
      setError(
        `You have selected ${itemCount} images but this grid will only hold ${itemCapacity} items. Please reduce the number of items or increase the number of columns or rows.`
      );
      setIsValid(false);
      return false;
    } else if (!isValidScenario) {
      setError(
        `Grid size exceeds maximum limit based on selected logos. Please reduce the number of columns and/or rows to a maximum of ${itemCount}.`
      );
      setIsValid(false);
      return false;
    } else {
      setError(undefined);
    }

    return true;
  }
  function validateCircle(rows: number) {
    if (rows > itemCount) {
      const rowItemDiff = rows - itemCount;
      setError(
        `The selected number of images is '${itemCount}', and the row size is '${rows}', which exceeds the maximum limit. Please reduce the number of rows by '${rowItemDiff}' or select more images.`
      );
      setIsValid(false);
    } else {
      setError(undefined);
      setIsValid(true);
    }
  }

  const handleColumnChange = (Value: string | undefined) => {
    if (Value) {
      const Column = parseInt(Value);
      setColumns(Column);
      setAutoCalculate(false);
      const calculatedRows = Math.ceil(itemCount / Column);
      setRows(calculatedRows);
      validateItemCount(Column, calculatedRows);
    }
  };
  const handleRowChange = (Value: string | undefined) => {
    if (Value) {
      if (isShapeOval) {
        validateCircle(parseInt(Value));
      } else {
        const Row = parseInt(Value);
        setRows(Row);
        setAutoCalculate(false);
        const calculatedColumns = Math.ceil(itemCount / Row);
        setColumns(calculatedColumns);
        validateItemCount(calculatedColumns, Row);
      }
    }
  };
  return (
    <>
      {error && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          {error}
        </MessageBar>
      )}
      <section className="GOPanel-panel">
        {!isShapeOval && (
          <>
            <div className="GOPanel-heading">Columns</div>
            {!isShapeLine && (
              <SpinButton
                label="Count"
                value={columns.toString()}
                onChange={(_ev, value) => {
                  setColumns(parseInt(value ?? "1"));
                  setAutoCalculate(false);
                  handleColumnChange(value);
                }}
                min={1}
                max={100}
                step={1}
                incrementButtonAriaLabel="Increase columns by 1"
                decrementButtonAriaLabel="Decrease columns by 1"
                styles={ColumnSpinnerStyles}
              />
            )}
            {!isShapeNull && (
              <Dropdown
                label="Alignment"
                options={alignmentOptions}
                defaultSelectedKey={isDistributeHorizontallySelected ? "please select" : alignment}
                disabled={isDistributeHorizontallySelected}
                responsiveMode={ResponsiveMode.large}
                onChange={(_event, options) => {
                  setAlignment(options?.key);
                }}
                styles={dropdownStyles}
              />
            )}
          </>
        )}

        <div className="GOPanel-heading">Rows</div>
        {!isShapeLine && (
          <SpinButton
            label="Count"
            value={rows.toString()}
            onChange={(_ev, value) => {
              setRows(parseInt(value ?? "1"));
              setAutoCalculate(false);
              handleRowChange(value);
            }}
            min={1}
            max={100}
            step={1}
            incrementButtonAriaLabel="Increase rows by 1"
            decrementButtonAriaLabel="Decrease rows by 1"
            styles={RowSpinnerStyles}
          />
        )}
        {!isShapeOval && !isShapeNull && (
          <Checkbox
            label="Distribute Horizontally"
            checked={isDistributeHorizontallySelected}
            onChange={() => {
              setDistributeHorizontallySelected(!isDistributeHorizontallySelected);
            }}
            boxSide="end"
            styles={distributeCheckBoxStyles}
          />
        )}
        <div className="GOPanel-heading">Other Options</div>

        {isShapeSelected && (
          <div className="GOPanel-section">
            <SpinButton
              label="Padding (px)"
              value={padding.toString()}
              onChange={(_ev, value) => {
                setPadding(value);
                dispatch(UpdateLogoPadding({ logoPadding: Number(value) }));
              }}
              min={0}
              max={100}
              step={1}
              incrementButtonAriaLabel="Increase padding by 1"
              decrementButtonAriaLabel="Decrease padding by 1"
              styles={SpinnerStyles}
            />
          </div>
        )}

        {isShapeSelected && (
          <div className="GOPanel-section">
            <Checkbox
              label="Retain Shape"
              checked={isRetainShapeSelected}
              onChange={() => setIsRetainShapeSelected(!isRetainShapeSelected)}
              boxSide="end"
              styles={retainShapeCheckBoxStyles}
            />
          </div>
        )}

        <div className="GOPanel-section">
          <Checkbox
            label="Compress Images"
            checked={isCompressImagesSelected}
            onChange={() => dispatch(UpdateCompressImagesInputStatus({ logoFormat: !isCompressImagesSelected }))}
            boxSide="end"
            styles={compressImagesCheckBoxStyles}
          />
        </div>
      </section>
      {retainShapeValidationErrorMessage ? (
        <p className="GoPanel-retainShape-errorMessage">Please choose a shape or deselect the Retain Shape option.</p>
      ) : null}
    </>
  );
}
