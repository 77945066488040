import React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  DefaultButton,
  Dialog,
  DialogType,
  PrimaryButton,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { supportFormRequest } from "../../Services/API/supportJirraTicketCreation";
import { useFormik } from "formik";
import SupportTicketSubmission from "./supportTicketSubmission";
import * as Yup from "yup";
import {
  categoryOptions,
  initialSubCategory,
  TechnicalSubCategory,
  contentSubCategory,
} from "../../Util/supportHelpers";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { TicketResponse } from "./SupportPageInterfaces";
import "./Styles/support.css";
import wlConfig from "../../../wlConfig";

const SupportPage = () => {
  const history = useHistory();
  const maxChar: number = 1000;
  const [charCount, setCharCount] = useState<number>(1000);
  const [jiraTicketResponse, setJiraTicketResponse] = useState<TicketResponse>({ data: undefined, success: undefined });
  const [registeredEmail, setRegisteredEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
const version = wlConfig.version;
  useEffect(() => {
    async function checkUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setRegisteredEmail(user.signInUserSession.idToken.payload.email);
      } catch (error) {
        setRegisteredEmail("");
      }
    }

    checkUser();
  }, []);

  useEffect(() => {}, []);
  const formik = useFormik({
    initialValues: {
      requesterEmailAddress: registeredEmail,
      category: "",
      subCategory: "",
      additionalInfo: "",
    },
    validationSchema: Yup.object({
      requesterEmailAddress: Yup.string().required("Email is required."),
      category: Yup.string().required("Category is required."),
      subCategory: Yup.string().required("Sub-category is required."),
      additionalInfo: Yup.string()
        .max(1024, "Must be 1024 characters or less.")
        .required("Additional information is required."),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: ({ requesterEmailAddress, category, subCategory, additionalInfo }, { setSubmitting, resetForm }) => {
      setLoading(true);
      setSubmitting(true);
    
      // Append the version string to additionalInfo
      const versionString =`LC Version - ${wlConfig.version}`;
      const unversionedAdditionalInfo = `Testing - please IGNORE this ticket - ${additionalInfo}`
      const versionedAdditionalInfo = `${versionString} ${unversionedAdditionalInfo}`;
      

      const formData = {
        requesterEmailAddress,
        category,
        subCategory,
        additionalInfo: category == "Technical" ? versionedAdditionalInfo : unversionedAdditionalInfo,
      };
    
      supportFormRequest(formData).then((responseData) => {
        setJiraTicketResponse(responseData);
        setLoading(false);
      });
    
      resetForm();
      setSubmitting(false);
    },
    
      
     
  });

  function Busy() {
    return (
      <Spinner
        className="support-spinner"
        label="Please wait..."
        ariaLive="assertive"
        labelPosition="bottom"
        size={SpinnerSize.large}
      />
    );
  }

  return (
    <>
      {loading && (
        <Dialog
          hidden={!loading}
          onDismiss={() => history.goBack()}
          dialogContentProps={{
            type: DialogType.normal,
          }}
        >
          <Busy />
        </Dialog>
      )}

      {jiraTicketResponse.success === true && (
        <SupportTicketSubmission success={jiraTicketResponse.success} data={jiraTicketResponse.data} />
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="page">
          <h3 className="support-heading">Submit a support request.</h3>

          {/* Email */}
          <label className="form-field-label">
            <span className="asterisk-red">*</span>Email
          </label>
          <TextField type="email" {...formik.getFieldProps("requesterEmailAddress")} />
          <div className="validation-error">
            {formik.touched.requesterEmailAddress && formik.errors.requesterEmailAddress}
          </div>

          {/* Category */}
          <label className="form-field-label form-field-spacing">
            <span className="asterisk-red">*</span>Category
          </label>
          <Dropdown
            placeholder="Please Select"
            options={categoryOptions}
            responsiveMode={ResponsiveMode.large}
            onChange={(_event, options) => {
              formik.setFieldValue("category", options?.key);
            }}
            selectedKey={formik.values.category}
          />
          <div className="validation-error">{formik.touched.category && formik.errors.category}</div>

          {/* Sub-Category */}
          <label className="form-field-label form-field-spacing">
            <span className="asterisk-red">*</span>Sub-category
          </label>
          <Dropdown
            placeholder="Please Select"
            className="label"
            disabled={formik.values.category=== "Technical" || formik.values.category === "Content" ? false:true}
            responsiveMode={ResponsiveMode.large}
            options={
              formik.values.category !== ""
                ? formik.values.category === "Technical"
                  ? TechnicalSubCategory
                  : initialSubCategory || formik.values.category === "Content"
                  ? contentSubCategory
                  : initialSubCategory
                : initialSubCategory
            }
            onChange={(_event, options) => {
              formik.setFieldValue("subCategory", options?.key);
            }}
            selectedKey={formik.values.subCategory}
          />
          <div className="validation-error">{formik.touched.subCategory && formik.errors.subCategory}</div>

          <label className="form-field-label form-field-spacing">
            <span className="asterisk-red">*</span>Additional Information
          </label>
          <TextField
            multiline
            maxLength={1000}
            rows={12}
            value={formik.values.additionalInfo}
            onChange={(event) => {
              const inputValue = (event.target as HTMLTextAreaElement).value;
              setCharCount(maxChar - inputValue.length);
              formik.setFieldValue("additionalInfo", inputValue);
            }}
          />
          <p className="char-counts">{charCount} remaining characters.</p>
          <div className="validation-error">{formik.touched.additionalInfo && formik.errors.additionalInfo}</div>

          <div className="btns-container support-footer">
            <PrimaryButton text="Submit" type="submit" className="submitButton" />
            <DefaultButton text="Cancel" onClick={() => history.goBack()} className="cancelButton" />
          </div>
        </div>
      </form>
    </>
  );
};

export default SupportPage;
