import { mergeStyleSets } from "@fluentui/merge-styles";
import { appTheme } from "../../../../theme";
import { ISearchPageClassNames } from "../searchPage.Interfaces";

export const getClassNames = (): ISearchPageClassNames => {
  return mergeStyleSets({
    bottomPanelClass: {
      paddingBottom: "8px",
      paddingTop: "8px",
      marginBottom: "22px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderTop: `2px solid ${appTheme.palette!.themePrimary}`,
    },
    inputWrapper: {
      flexGrow: 1,
      '& span[id^="selected-items-id"]': {
        maxHeight: "100px", // approx. 3 lines of tags
        overflow: "auto",
      },
    },
  });
};
