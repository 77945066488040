import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { insertedLogoFormatRedux } from "../redux.interfaces";

const initialState: insertedLogoFormatRedux = {
  //  selectedTab:isAlias ? "StockSymbol" :"Name"
  logoFormat:false
};

export const insertedLogoFormatSlice = createSlice({
  name: "insertedLogoFormat",
  initialState,
  reducers: {
    UpdateCompressImagesInputStatus: (state, action: PayloadAction<{ logoFormat: any }>) => {
      state.logoFormat = action.payload.logoFormat;
    },
  },
  
});

export default insertedLogoFormatSlice.reducer;
export const { UpdateCompressImagesInputStatus } = insertedLogoFormatSlice.actions;