import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IModalProps,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { GridDimensions } from "../../ImageInsertion/ImageInsertion.Interfaces";
import { GridOptionsPanel } from "../GridOptionsPanel/GridOptionsPanel";
import { GridOptionsPopupProps } from "../GridOptions.Interfaces";
import "./Styles/GridOptionsPopUp.css";
import { findShape } from "../../ImageInsertion/ImageInsertionHelpers";
export function GridOptionsPopup({
  show,
  itemCount,
  alignment,
  setAlignment,
  onInsert,
  onCancel,
  isRetainShapeSelected,
  setIsRetainShapeSelected,
  setIsShapeSelected,
  isShapeSelected,
  retainShapeValidationErrorMessage,
  setRetainShapeValidationErrorMessage,
  isDistributeHorizontallySelected,
  setDistributeHorizontallySelected,
}: GridOptionsPopupProps) {
  const [insertGridDimensions, setInsertGridDimensions] = useState<GridDimensions>({ columns: 1, rows: 1 });
  const [error, setError] = useState<string | undefined>(undefined);
  const [isShapeOval, setIsShapeOval] = useState<boolean>(false);
  const [resolvedCalc, setResolvedCalc] = useState<boolean>(false);
  const [isShapeNull, setIsShapeNull] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const dialogContentProps = {
    type: DialogType.normal,
    title: "Layout Options",
    closeButtonAriaLabel: "Cancel insert",
  };

  const modalProps: IModalProps = {
    styles: {
      main: {
        minHeight: "inherit",
      },
    },
  };

  const checkShapeForOval = async () => {
    const shape = await findShape();

    if (shape === null) {
      setIsShapeNull(true);
      setResolvedCalc(true);
    } else {
      setIsShapeNull(false);
    }

    if (shape.name.includes("Oval")) {
      setIsShapeOval(true);
    }

    setResolvedCalc(true);
  };

  useEffect(() => {
    checkShapeForOval();
  }, [checkShapeForOval]);
  async function onClickInsert() {
    const shape = await findShape();
    if (isValid) {
      if (shape === null && isRetainShapeSelected === true) {
        setRetainShapeValidationErrorMessage(true);
      } else {
        setRetainShapeValidationErrorMessage(false);
        onInsert(insertGridDimensions);
      }
    }
  }
  return (
    // Popping up the dialog leaves a little anchor that had some height, so remove that.
    // Visibility is controlled with the Dialog hidden flag
    <div className="GOPop-dialog">
      <Dialog
        hidden={!show}
        onDismiss={() => {
          onCancel();
        }}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        {/* {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {error}
          </MessageBar>
        )} */}

        {!resolvedCalc && (
          <Spinner ariaLive="assertive" labelPosition="bottom" size={SpinnerSize.large} className="GOPop-spinner" />
        )}

        {resolvedCalc && (
          <GridOptionsPanel
            itemCount={itemCount}
            updateGridDimensions={setInsertGridDimensions}
            alignment={alignment}
            setAlignment={setAlignment}
            isRetainShapeSelected={isRetainShapeSelected}
            setIsRetainShapeSelected={setIsRetainShapeSelected}
            setIsShapeSelected={setIsShapeSelected}
            isShapeSelected={isShapeSelected}
            retainShapeValidationErrorMessage={retainShapeValidationErrorMessage}
            isShapeOval={isShapeOval}
            isDistributeHorizontallySelected={isDistributeHorizontallySelected}
            setDistributeHorizontallySelected={setDistributeHorizontallySelected}
            isShapeNull={isShapeNull}
            setError={setError}
            error={error}
            setIsValid={setIsValid}
          />
        )}

        <DialogFooter>
          {/* <PrimaryButton onClick={() => (validateItemCount() ? onInsert(insertGridDimensions) : {})} text="Insert" /> */}
          <PrimaryButton onClick={() => onClickInsert()} text="Insert" />
          <DefaultButton
            onClick={() => {
              setDistributeHorizontallySelected(false);
              setAlignment("center");
              onCancel();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
}
