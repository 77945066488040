import { PrimaryButton, DefaultButton, TextField, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import SignUpConfirmation from "../../Components/SignUpConfirmation/SignUpConfirmation";
import { error } from "./SignUpPage.Interfaces";
import "./Styles/SignUpPage.css";

const SignUpPage = () => {
  const [codeConfirmation, setCodeConfirmation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<error>({ message: "", title: "" });
  const history = useHistory();

  async function signUpFunc(values: { email: string; password: string; name: string }) {
    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,

        attributes: {
          email: values.email,
          name: values.name,
        },
      });

      setEmail(values.email);
      setCodeConfirmation(!codeConfirmation);
    } catch (errorResponse) {
      let error = await handleError(errorResponse);
      setErrorMessage(error);
    }
  }

  async function handleError(error: any) {
    switch (error.code) {
      case "UsernameExistsException":
        return {
          message: "An account with the given email already exists.",
          title: "User Already Exist",
        };
      case "InvalidPasswordException":
        return { message: error.message, title: "Invalid Password" };
      default:
        return { message: error.message, title: error.code };
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required."),
      name: Yup.string().required("Name is required."),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Password must contain at least one special character")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .required("Confirm password is required.")
        .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    }).shape({}),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      signUpFunc(values);
    },
  });

  return (
    <>
      {!codeConfirmation && (
        <div className="page">
          <h3 className="page-slogan">Sign in to start adding logos.</h3>
          <h3 className="page-heading">Sign up</h3>
          <form onSubmit={formik.handleSubmit}>
            {/* Email / Username*/}
            <label className="form-field-label">
              <span className="asterisk-red">*</span>Email (username)
            </label>
            <TextField {...formik.getFieldProps("email")} />
            <div className="validation-error">{formik.touched.email && formik.errors.email}</div>

            {/* Name */}
            <label className="form-field-label form-field-spacing">
              <span className="asterisk-red">*</span>Name
            </label>
            <TextField {...formik.getFieldProps("name")} />
            <div className="validation-error">{formik.touched.name && formik.errors.name}</div>

            {/* Password */}
            <label className="form-field-label form-field-spacing">
              <span className="asterisk-red">*</span>Password
            </label>
            <TextField type="password" {...formik.getFieldProps("password")} />
            <div className="validation-error">{formik.touched.password && formik.errors.password}</div>

            {/* Confirm Password */}
            <label className="form-field-label form-field-spacing">
              <span className="asterisk-red">*</span>Confirm password
            </label>
            <TextField type="password" {...formik.getFieldProps("confirmPassword")} />
            <div className="validation-error">{formik.touched.confirmPassword && formik.errors.confirmPassword}</div>

            <div className="btns-container">
              <PrimaryButton type="submit" text="Submit" />
              <DefaultButton text="Cancel" onClick={() => history.push("/")} />
            </div>
          </form>

          {errorMessage.message != "" && (
            <div className="validation-error form-field-spacing">Error: {errorMessage.message}</div>
          )}

          <hr className="form-field-spacing" />
          <div className="sign-up-footer">
            <p>Received confirmation code in past 24 hours?</p>
            <div>
              <u role="button" onClick={() => setCodeConfirmation(true)} className="sign-up-footer-btn">
                Click here
              </u>
            </div>
          </div>
        </div>
      )}
      {codeConfirmation && <SignUpConfirmation email={email} />}
    </>
  );
};

export default SignUpPage;
