import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { carouselPhotoIndexRedux } from "../redux.interfaces";


const initialState: carouselPhotoIndexRedux = {
  currentPhoto:[],
    activeIndex:0,
};

export const carouselPhotoIndexSlice = createSlice({
  name: "carouselPhotoIndex",
  initialState,
  reducers: {
    UpdateActiveIndexStatus: (state, action: PayloadAction<{ activeIndex: any }>) => {
      state.activeIndex = action.payload.activeIndex;
    },
  },
});

export default carouselPhotoIndexSlice.reducer;
export const { UpdateActiveIndexStatus } = carouselPhotoIndexSlice.actions;
