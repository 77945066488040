import React, { useState } from "react";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import { useAppSelector } from "../../Redux/store/store";
import { ConfirmSignOut } from "../../Components/ConfirmSignOut/ConfirmSignOut";
import "./Styles/headerStyles.css";

const Header = () => {
  const AuthCheck = useAppSelector((state) => state.authStatus.isAuthenticated);
  const isAmplifyConfigured = useAppSelector((state) => state.amplifyConfiguredRedux.isAmplifyConfigured);
  const bannerLogoUrl = useAppSelector((state) => state.amplifyConfiguredRedux.bannerLogoUrl);
  const [showLogOut, setShowLogOut] = useState(false);

  return (
    <>
      <div className="header-container">
        {AuthCheck && isAmplifyConfigured && <HamburgerMenu setShowLogOut={setShowLogOut} />}
        {bannerLogoUrl && (
 <img src={bannerLogoUrl} alt="BrandLogo" className="brandLogo" />
        )}
       
      </div>

      <ConfirmSignOut showLogOut={showLogOut} setShowLogOut={setShowLogOut} />
    </>
  );
};

export default Header;
