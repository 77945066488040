import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import wlConfig from "../../../wlConfig";
import NativeLogin from "../../Components/NativeLogin/NativeLogin";
import "./Styles/StartPage.css";
const StartPage = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  // const isAmplifyConfigured = useAppSelector((state) => state.amplifyConfiguredRedux.isAmplifyConfigured);

  return (
    <>
      <div className="page">
        <h3 className="page-slogan">Sign in to start adding logos.</h3>
        <NativeLogin setError={setError} isLoading={isLoading} setIsLoading={setIsLoading} />

        {!isLoading && (
          <>
            <div className="sp-footer">
              <div>
                <u role="button" onClick={() => history.push("/sign-up")}>
                  Sign-up
                </u>
              </div>
              <div>
                <u role="button" onClick={() => history.push("/support")}>
                  Contact support
                </u>
              </div>
              <p>Version - {wlConfig.version}</p>
            </div>

            <p className="sp-auth-error">{error}</p>
          </>
        )}
      </div>
    </>
  );
};

export default StartPage;
