import React from "react";
import { Dialog, DialogFooter, SpinnerSize, PrimaryButton, Spinner } from "@fluentui/react";
import "./Styles/RequestMissingLogos.css";

export function RequestMissingLogos({
  requestMissingLogos,
  setRequestMissingLogos,
  isRequestMissingLogosSuccessful,
  setIsRequestMissingLogosSuccessful,
}: {
  requestMissingLogos: boolean;
  isRequestMissingLogosSuccessful: string;
  setRequestMissingLogos: () => void;
  setIsRequestMissingLogosSuccessful: () => void;
}) {
  function Busy() {
    return (
      <Spinner
        label="Please wait..."
        ariaLive="assertive"
        labelPosition="bottom"
        size={SpinnerSize.large}
        className="rqo-spinner"
      />
    );
  }

  function handleSubmit() {
    setRequestMissingLogos();
    setIsRequestMissingLogosSuccessful();
  }

  return (
    <>
      {requestMissingLogos ? (
        <Dialog hidden={!requestMissingLogos} onDismiss={() => setRequestMissingLogos()}>
          {isRequestMissingLogosSuccessful !== undefined ? (
            <div>
              <p className="rqo-message">{isRequestMissingLogosSuccessful}</p>
              <DialogFooter>
                <PrimaryButton onClick={() => handleSubmit()} text="Ok" />
              </DialogFooter>
            </div>
          ) : (
            <Busy />
          )}
        </Dialog>
      ) : null}
    </>
  );
}
