/* eslint-disable no-undef */
import * as React from "react";

import { HashRouter as Router, Route, Switch, Link, useHistory } from "react-router-dom";

import Header from "./Src/Layouts/Header/Header";
import Footer from "./Src/Layouts/Footer/Footer";
import StartPage from "./Src/Pages/StartPage/StartPage";
import SupportPage from "./Src/Pages/SupportPage/SupportPage";
import wlConfig from "./wlConfig";
import { Amplify, Auth } from "aws-amplify";
import { useEffect } from "react";
import ProtectedRoute from "./Src/Auth/ProtectedRoutes";
import SearchPage from "./Src/Pages/SearchPage/searchPage";
import ForgotPasswordPage from "./Src/Pages/ForgotPasswordPage/ForgotPasswordPage";
import SignUpPage from "./Src/Pages/SignUpPage/SignUpPage";
import { UpdateAuthStatus } from "./Src/Redux/features/authStatus";

import { useAppDispatch, useAppSelector } from "./Src/Redux/store/store";
import { getTenantId, constructAbsoluteUrl } from "./url-helpers";
import "./Src/GlobalStyling.css";
import { useIdleTimer } from "react-idle-timer";
import AddLogo from "./Src/Pages/AddLogoPage/AddLogo";
import { UpdateBannerLogoUrl, UpdateIsAmplifyConfiguredStatus, UpdateLongAuthSession } from "./Src/Redux/features/amplifyConfiguredStatus";
import { AuthCheck } from "./Src/Components/AuthCheck/AuthCheck";

export interface AppProps {
  isOfficeInitialized: boolean;
}

const App = ({ isOfficeInitialized }: AppProps) => {
  const dispatch = useAppDispatch();
  // const history = useHistory();
  const isAmplifyConfigured = useAppSelector((state) => state.amplifyConfiguredRedux.isAmplifyConfigured);
  const isUserLogin = useAppSelector((state) => state.authStatus.isAuthenticated);
  const hasLongAuthSession = useAppSelector((state) => state.amplifyConfiguredRedux.hasLongAuthSession);
  const logout = async () => {
    if (isUserLogin) {
      dispatch(UpdateAuthStatus({ isAuthenticated: false }));
      localStorage.clear();
      window.location.reload();
    }
    // console.log("calling idle functionto logout");
  };

  
//commenting out idle log out function call.

  // Timeout length = ms * seconds * minutes
  const IdleTimeout = 1000 * 60 * 20;
  const onIdle = () => {
    logout();
  };
  

  useIdleTimer({
    onIdle,
    timeout: IdleTimeout,
    debounce: 1000,
    eventsThrottle: 1000,
    disabled: hasLongAuthSession,
  });


  async function startupPreRequisites() {
    try {
      const tenantId = await getTenantId();
      // console.log(tenantId);
      if (tenantId) {
        const response = await fetch(`${wlConfig.configurationEndpoint}/tenant/${tenantId}`);
        if (!response.ok) {
          throw new Error(JSON.stringify(await response.json()));
        }
        const config = await response.json();
        // console.log(config, "tenant config from api");
        const authObj = {
          Auth: {
            identityPoolId: config.data.identityPoolId,
            region: wlConfig.region,
            userPoolId: wlConfig.userPoolId,
            userPoolWebClientId: config.data.clientId,
          },
          oauth: {
            domain: wlConfig.cognitoDomain,
            scope: wlConfig.scope,
            redirectSignIn: constructAbsoluteUrl("/login.html"),
            redirectSignOut: constructAbsoluteUrl("/taskpane.html"),
            responseType: wlConfig.responseType,
            clientId: config.data.clientId,
          },
        };
        Amplify.configure(authObj);
        dispatch(UpdateIsAmplifyConfiguredStatus({ isAmplifyConfigured: true }));
        dispatch(UpdateBannerLogoUrl({ bannerLogoUrl: config.data.bannerLogoUrl }));
        dispatch(UpdateLongAuthSession({ hasLongAuthSession: config.data.hasLongAuthSession }));
        // logout();
      } else {
        throw new Error("Tenant ID is not available");
      }
    } catch (error) {
      console.error("Failed to fetch Amplify configuration:", error);
      throw error;
    }
  }
  useEffect(() => {
    (async () => {
      try {
        if (isOfficeInitialized === true) {
          await startupPreRequisites();
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [isOfficeInitialized]);

  return (
    <>
      <Router basename="/">
        <Header />
        <Switch>
          {isAmplifyConfigured && (
            <>
              <Route exact path="/" component={AuthCheck} />
              <Route exact path="/start-page" component={StartPage} />
              <Route exact path="/forgot-password" component={ForgotPasswordPage} />
              <Route exact path="/sign-up" component={SignUpPage} />
              <Route exact path="/support" component={SupportPage} />
              <ProtectedRoute exact path="/search" component={SearchPage} />
              <ProtectedRoute exact path="/addLogo" component={AddLogo} />
            </>
          )}
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
